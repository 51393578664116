/* eslint-disable react/destructuring-assignment */
import dayjs from 'dayjs';
import React, { ComponentPropsWithRef, useCallback, useContext, useEffect, useRef } from 'react';

import { TextField } from 'src/shared/ui/textField';

import { DATE_FORMAT } from '../constants';
import { DatepickerContext } from '../contexts/DatepickerContext';
import { dateIsValid, parseFormattedDate } from '../helpers';

import { ToggleButton } from './ToggleButton';

type Props = ComponentPropsWithRef<'input'> & {
  error?: string;
  setContextRef?: (ref: React.RefObject<HTMLInputElement>) => void;
  iconPosition?: 'start' | 'end';
  isBorderPrimary?: boolean;
};

const Input: React.FC<Props> = (e: Props) => {
  // Context
  const {
    period,
    dayHover,
    changeDayHover,
    calendarContainer,
    inputText,
    changeInputText,
    hideDatepicker,
    changeDatepickerValue,
    asSingle,
    placeholder,
    separator,
    disabled,
    inputClassName,
    toggleClassName,
    toggleIcon,
    readOnly,
    displayFormat,
    inputId,
    inputName,
    classNames,
    popoverDirection,
  } = useContext(DatepickerContext);

  // UseRefs
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Functions
  const getClassName = useCallback(() => {
    const input = inputRef.current;

    if (input && typeof classNames !== 'undefined' && typeof classNames?.input === 'function') {
      return classNames.input(input);
    }

    // eslint-disable-next-line no-nested-ternary
    return typeof inputClassName === 'function'
      ? inputClassName('')
      : typeof inputClassName === 'string' && inputClassName !== ''
      ? inputClassName
      : '';
  }, [inputRef, classNames, inputClassName]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line react/destructuring-assignment
      const inputValue = e.target.value;

      const dates = [];

      if (asSingle) {
        const date = parseFormattedDate(inputValue, displayFormat);
        if (dateIsValid(date.toDate())) {
          dates.push(date.format(DATE_FORMAT));
        }
      } else {
        const parsed = inputValue.split(separator);

        let startDate = null;
        let endDate = null;

        if (parsed.length === 2) {
          startDate = parseFormattedDate(parsed[0], displayFormat);
          endDate = parseFormattedDate(parsed[1], displayFormat);
        } else {
          const middle = Math.floor(inputValue.length / 2);
          startDate = parseFormattedDate(inputValue.slice(0, middle), displayFormat);
          endDate = parseFormattedDate(inputValue.slice(middle), displayFormat);
        }

        if (
          dateIsValid(startDate.toDate()) &&
          dateIsValid(endDate.toDate()) &&
          startDate.isBefore(endDate)
        ) {
          dates.push(startDate.format(DATE_FORMAT));
          dates.push(endDate.format(DATE_FORMAT));
        }
      }

      if (dates[0]) {
        changeDatepickerValue(
          {
            startDate: dates[0],
            endDate: dates[1] || dates[0],
          },
          // eslint-disable-next-line react/destructuring-assignment
          e.target,
        );
        if (dates[1]) changeDayHover(dayjs(dates[1]).add(-1, 'day').format(DATE_FORMAT));
        else changeDayHover(dates[0]);
      }

      // eslint-disable-next-line react/destructuring-assignment
      changeInputText(e.target.value);
    },
    [asSingle, displayFormat, separator, changeDatepickerValue, changeDayHover, changeInputText],
  );

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (e.key === 'Enter') {
        const input = inputRef.current;
        if (input) {
          input.blur();
        }
        hideDatepicker();
      }
    },
    [hideDatepicker],
  );

  const renderToggleIcon = useCallback(
    (isEmpty: boolean) => {
      return typeof toggleIcon === 'undefined' ? (
        <ToggleButton isEmpty={isEmpty} />
      ) : (
        toggleIcon(isEmpty)
      );
    },
    [toggleIcon],
  );

  const getToggleClassName = useCallback(() => {
    const button = buttonRef.current;

    if (
      button &&
      typeof classNames !== 'undefined' &&
      typeof classNames?.toggleButton === 'function'
    ) {
      return classNames.toggleButton(button);
    }

    const defaultToggleClassName =
      'absolute right-0 top-[12px] px-3 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed';

    // eslint-disable-next-line no-nested-ternary
    return typeof toggleClassName === 'function'
      ? toggleClassName(defaultToggleClassName)
      : typeof toggleClassName === 'string' && toggleClassName !== ''
      ? toggleClassName
      : defaultToggleClassName;
  }, [toggleClassName, buttonRef, classNames]);

  // UseEffects && UseLayoutEffect
  useEffect(() => {
    if (inputRef && e.setContextRef && typeof e.setContextRef === 'function') {
      e.setContextRef(inputRef);
    }
  }, [e, inputRef]);

  useEffect(() => {
    const button = buttonRef?.current;

    function focusInput(e: Event) {
      e.stopPropagation();
      const input = inputRef.current;

      if (input) {
        input.focus();
        if (inputText) {
          changeInputText('');
          if (dayHover) {
            changeDayHover(null);
          }
          if (period.start && period.end) {
            changeDatepickerValue(
              {
                startDate: null,
                endDate: null,
              },
              input,
            );
          }
        }
      }
    }

    if (button) {
      button.addEventListener('click', focusInput);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', focusInput);
      }
    };
  }, [
    changeDatepickerValue,
    changeDayHover,
    changeInputText,
    dayHover,
    inputText,
    period.end,
    period.start,
    inputRef,
  ]);

  useEffect(() => {
    const div = calendarContainer?.current;
    const input = inputRef.current;

    function showCalendarContainer() {
      if (div && div.classList.contains('hidden')) {
        div.classList.remove('hidden');
        div.classList.add('block');

        // window.innerWidth === 767
        // eslint-disable-next-line eqeqeq
        const popoverOnUp = popoverDirection == 'up';
        const popoverOnDown = popoverDirection === 'down';
        if (
          popoverOnUp ||
          (window.innerWidth > 767 &&
            window.screen.height - 100 < div.getBoundingClientRect().bottom &&
            !popoverOnDown)
        ) {
          div.classList.add('bottom-full');
          div.classList.add('mb-2.5');
          div.classList.remove('mt-2.5');
        }

        setTimeout(() => {
          div.classList.remove('translate-y-4');
          div.classList.remove('opacity-0');
          div.classList.add('translate-y-0');
          div.classList.add('opacity-1');
        }, 1);
      }
    }

    if (div && input) {
      input.addEventListener('focus', showCalendarContainer);
    }

    return () => {
      if (input) {
        input.removeEventListener('focus', showCalendarContainer);
      }
    };
  }, [calendarContainer, popoverDirection]);

  const button = (
    <button
      type="button"
      ref={buttonRef}
      disabled={disabled}
      className={getToggleClassName()}
    >
      {renderToggleIcon(inputText == null || !inputText?.length)}
    </button>
  );

  return (
    <TextField
      ref={inputRef}
      type="text"
      error={e.error}
      className={getClassName()}
      style={{
        cursor: 'pointer',
        borderColor: e.isBorderPrimary ? '#123C8E' : undefined,
      }}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={
        placeholder || `${displayFormat}${asSingle ? '' : ` ${separator} ${displayFormat}`}`
      }
      value={inputText}
      id={inputId}
      name={inputName}
      autoComplete="off"
      // eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role
      role="presentation"
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      startIcon={e.iconPosition === 'start' && !inputText ? button : undefined}
      endIcon={
        e.iconPosition === 'end' && inputText ? (
          <button
            type="button"
            ref={buttonRef}
            disabled={disabled}
            className={getToggleClassName()}
          >
            {renderToggleIcon(inputText == null || !inputText?.length)}
          </button>
        ) : undefined
      }
      {...e}
    />
  );
};

export { Input };
