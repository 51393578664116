import dayjs from 'dayjs';
import { FC, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import clsx from 'clsx';

import { useOutsideClick } from 'src/shared/hooks/useOutsideClick';
import { Typography } from 'src/shared/ui/typography';
import { ActivityJob } from 'src/shared/types';

import { TaskManagementInfo } from '../taskManagementInfo';
import { TaskTimeInfo } from '../taskTimeInfo';

interface ActivityJobUpdatesTooltipProps {
  isOpen: boolean;
  hideUpdates: () => void;
  card: ActivityJob;
  indexOfUpdate: number;
  timeZone: string;
}

const ActivityJobUpdatesTooltip: FC<ActivityJobUpdatesTooltipProps> = ({
  isOpen,
  hideUpdates,
  card,
  indexOfUpdate,
  timeZone,
}) => {
  const updatesRef = useRef<HTMLDivElement>(null);

  useOutsideClick(updatesRef, hideUpdates, isOpen);

  if (!card.updates || !card.updates[indexOfUpdate]) {
    hideUpdates();

    return null;
  }

  const currentUpdate = card.updates[indexOfUpdate];

  return (
    <Tooltip
      id={`updates-${card.id}`}
      place="bottom-start"
      classNameArrow="bg-transparent"
      isOpen={isOpen}
      className={clsx(isOpen ? '!opacity-100' : '!opacity-0')}
      style={{
        background: 'transparent',
        color: '#1F1F1F',
        padding: 0,
        boxShadow: '0px 26px 62px -10px rgba(102,102,102,0.16)',
      }}
    >
      <div
        ref={updatesRef}
        className="w-[478px] overflow-hidden"
      >
        <div className="mt-2 px-4 py-3 flex flex-col gap-2 rounded-lg bg-white border border-[#E9EFFE]">
          <Typography
            variant="p2"
            fontWeight="bold"
            className="shrink-0"
          >
            {card.id}
          </Typography>

          <Typography
            variant="c1"
            className="text-xs leading-[18px] text-textColor-secondary"
          >
            {`Updated ${dayjs.utc(currentUpdate.createdAt).tz(timeZone).format('M/D/YYYY h:mm A')}`}
          </Typography>

          <TaskManagementInfo
            activityName={currentUpdate.activityName}
            provider={currentUpdate.providerName}
            crew={currentUpdate.providerTeam.name}
          />

          <TaskTimeInfo
            endWork={currentUpdate.actualEnd || card.endWork}
            startWork={currentUpdate.actualStart || card.startWork}
            isTimeColored
            timeZone={timeZone}
          />

          <div className="flex h-max">
            <div className="w-2 rounded-l-lg shrink-0 bg-semanticColor-warning" />

            <div className="py-3 pr-1.5 pl-3 flex flex-1 flex-col gap-2 rounded-r-lg bg-bgColor-ticketCard border-y border-y-textColor-light border-r border-r-textColor-light">
              <div className="flex flex-1 flex-col gap-1">
                <Typography
                  variant="c3"
                  className="text-xs leading-[18px]"
                >
                  Job Notes:
                </Typography>

                <Typography
                  variant="c2"
                  className="text-xs leading-[18px] text-textColor-secondary"
                >
                  {currentUpdate.notes}
                </Typography>
              </div>

              <div className="flex flex-1 flex-col gap-1">
                <Typography
                  variant="c3"
                  className="text-xs leading-[18px]"
                >
                  Job Delays:
                </Typography>

                <Typography
                  variant="c2"
                  className="text-xs leading-[18px] text-textColor-secondary"
                >
                  {currentUpdate.delayCategoryName}
                </Typography>
              </div>

              <div className="flex flex-1 flex-col gap-1">
                <Typography
                  variant="c3"
                  className="text-xs leading-[18px]"
                >
                  Percent-Complete Estimate:
                </Typography>

                <Typography
                  variant="c2"
                  className="text-xs leading-[18px] text-textColor-secondary"
                >
                  {`${currentUpdate.percentComplete || 0} %`}
                </Typography>
              </div>

              <div className="flex flex-1 flex-col gap-1">
                <Typography
                  variant="c3"
                  className="text-xs leading-[18px]"
                >
                  Current Estimated Time:
                </Typography>

                <TaskTimeInfo
                  startWork={card.startWork}
                  endWork={currentUpdate.actualStart || card.startWork}
                  isStart
                  timeZone={timeZone}
                />

                <TaskTimeInfo
                  startWork={card.endWork}
                  endWork={currentUpdate.actualEnd || card.endWork}
                  isEnd
                  timeZone={timeZone}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export { ActivityJobUpdatesTooltip };
