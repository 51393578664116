import clsx from 'clsx';
import { FC, PropsWithChildren, HTMLAttributes } from 'react';

type IBodyModal = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const BodyModal: FC<IBodyModal> = ({ children, className, ...rest }) => {
  return (
    <div
      className={clsx('px-4', className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export { BodyModal };
