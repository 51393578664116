import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { Typography } from 'src/shared/ui/typography';
import { clsx } from 'src/shared/utils';

import { SvgRenderer } from '../svgRenderer';

type NavigationLinkProps = {
  label: string;
  description: string;
  icon: string;
  link: string;
  toggleNavigation?: () => void;
  isAppLink?: boolean;
  disabled?: boolean;
};

const NavigationLink: FC<NavigationLinkProps> = ({
  label,
  description,
  icon,
  link,
  toggleNavigation,
  isAppLink,
  disabled: isDisabled = false,
}) => {
  const navigate = useNavigate();

  const content = (
    <>
      <SvgRenderer svgUrl={icon} />

      <Typography
        variant="p1"
        fontWeight="bold"
        className="text-textColor-secondary"
      >
        {label}
      </Typography>

      <Tooltip
        id={`description-${label}`}
        place="bottom"
        content={description}
        className="max-w-[250px] z-[1] bg-[rgba(0,0,0,0.7)]"
      />
    </>
  );

  const className = clsx(
    'flex items-center gap-2 w-[226px] p-2 border rounded border-solid border-[white] hover:border-outlineColor-input-border hover:bg-[#F6F9FD] transition-all duration-200',
    isDisabled && 'cursor-not-allowed',
  );

  const navigateToAppLink = () => {
    if (toggleNavigation) {
      toggleNavigation();
    }

    if (isAppLink) {
      navigate(link);
      return;
    }

    window.open(link, '_blank', 'noreferrer');
  };

  return (
    <button
      type="button"
      className={className}
      onClick={navigateToAppLink}
      data-tooltip-id={`description-${label}`}
      disabled={isDisabled}
    >
      {content}
    </button>
  );
};

export { NavigationLink };
export type { NavigationLinkProps };
