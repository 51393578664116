import { FC, MouseEvent } from 'react';
import clsx from 'clsx';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';

import { ReactComponent as CarIcon } from 'src/assets/icons/outlined/shop/car.svg';
import { Icon } from 'src/shared/ui/icon';
import { Typography } from 'src/shared/ui/typography';
import { EquipmentEntity, EquipmentTab, Role, TicketEntity } from 'src/shared/types';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { TicketConnections, useUnassignFromTicketMutation } from 'src/store/api';
import { configActions, selectCurrentUser } from 'src/store/slices';
import { useAppDispatch } from 'src/store';
import { Tag } from 'src/shared/ui/tag';

type EquipmentCardProps = {
  disabled?: boolean;
  className?: string;
  cardId?: string;
  ticket?: TicketEntity;
  clearAfterDrag?: boolean;
  isTicketCard?: boolean;
} & EquipmentEntity;

const EquipmentCard: FC<EquipmentCardProps> = ({
  disabled = false,
  className,
  cardId,
  ticket,
  clearAfterDrag,
  isTicketCard,
  ...equipment
}) => {
  const dispatch = useAppDispatch();

  const [unassignFromTicket] = useUnassignFromTicketMutation();
  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;
  const isRental = equipment.IsRental;

  const [, drag] = useDrag(() => ({
    type: 'equipment',
    item: () => ({ ...equipment }),
    canDrag: () => not(isReadonly),
    end: async (_item, monitor) => {
      if (monitor.getDropResult() === null && clearAfterDrag && ticket) {
        try {
          await unassignFromTicket({
            ticketId: ticket.id,
            entityId: equipment.AssetID,
            entityType: TicketConnections.equipmentItem,
            type: equipment.IsRental ? EquipmentTab.rental : EquipmentTab.owned,
          }).unwrap();
        } catch {
          showToastErrorMessage(
            `Sorry, an error occurred, when you tried to unassign ${equipment?.UnitDescription} from ticket, please check your internet connection`,
          );
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const { UnitDescription, Type, UnitNo } = equipment;

  const openEquipmentDetails = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(configActions.setSelectedEquipmentItem(equipment));
  };

  return (
    <div
      ref={disabled ? undefined : drag}
      className={clsx(
        'z-[10] bg-bgColor-card border-[1px] rounded-lg w-full min-w-[310px] p-[8px] translate-x-0 translate-y-0',
        not(disabled) && not(isReadonly) && 'active:cursor-move',
        disabled && not(isTicketCard) && 'opacity-[0.4]',
        className,
      )}
    >
      <div className="flex flex-col h-full justify-between gap-2">
        <div className="flex justify-between items-center gap-x-2">
          <div className="w-6 h-6">
            <Icon
              icon={<CarIcon />}
              className="fill-brandingColor-primary-gradient w-6 h-6"
            />
          </div>

          <button
            type="button"
            className="w-full text-xs text-left font-bold leading-4"
            onClick={openEquipmentDetails}
          >
            {UnitDescription}
          </button>
        </div>

        <div className="flex justify-between items-center gap-x-2">
          <Typography
            variant="c1"
            className="text-textColor-secondary"
          >
            {Type}
          </Typography>

          {not(isRental) && <Typography variant="c1">{`#${UnitNo}`}</Typography>}

          {isRental && <Tag type="warning">Rental</Tag>}
        </div>
      </div>
    </div>
  );
};

export { EquipmentCard };
export type { EquipmentCardProps };
