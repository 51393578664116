import { Dispatch, FC, SetStateAction } from 'react';
import { useAppDispatch } from 'src/store';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { not } from 'src/shared/utils';
import {
  TypeNotation,
  RelationOptionsMap,
  TableManagerFieldValue,
  MetadataMap,
} from 'src/shared/types';
import { ContainerModal, HeaderModal, BodyModal, FooterModal } from 'src/shared/ui/modal/ui';
import { Button } from 'src/shared/ui/button';
import { adminTableActions } from 'src/store/slices';

import { RowFields } from './RowFields';

type DetailsTableRowModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  schema: TypeNotation[];
  relationOptions: RelationOptionsMap;
  selectedRow: Record<string, unknown> | null;
  setSelectedRow: Dispatch<SetStateAction<Record<string, TableManagerFieldValue> | null>>;
  fieldsMetadata: MetadataMap;
};

const DetailsTableRowModal: FC<DetailsTableRowModalProps> = ({
  schema,
  isOpen,
  selectedRow,
  fieldsMetadata,
  setSelectedRow,
  relationOptions,
  setIsOpen,
}) => {
  const dispatch = useAppDispatch();
  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
    if (isOpen) {
      setSelectedRow(null);
      dispatch(adminTableActions.selectRow({ id: null, typeModal: null }));
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedRow(null);
    dispatch(adminTableActions.selectRow({ id: null, typeModal: null }));
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="max-h-[calc(100vh-64px*2)] overflow-y-scroll w-full max-w-[80vw] p-0"
      childrenClassName="p-0"
    >
      <ContainerModal className="min-h-[368x] min-w-[451px]">
        <HeaderModal>
          <Typography
            variant="h2"
            fontWeight="bold"
            className=""
          >
            Details modal
          </Typography>
        </HeaderModal>
        <BodyModal>
          <RowFields
            schema={schema}
            selectedRow={selectedRow}
            relationOptions={relationOptions}
            fieldsMetadata={fieldsMetadata}
          />
        </BodyModal>
        <FooterModal>
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={closeModal}
          >
            Close
          </Button>
        </FooterModal>
      </ContainerModal>
    </Modal>
  );
};

export { DetailsTableRowModal };
