import { z } from 'zod';

export const addUpdateSchema = z
  .object({
    notes: z.string().optional(),
    delayCategory: z.object({
      name: z.string().optional(),
      id: z.string().optional(),
    }),
    percentComplete: z
      .string()
      .regex(
        /^((100((\.|,)[0-9]{1,2})?)|([0-9]{1,2}((\.|,)[0-9]{0,2})?))$/,
        'Percent-complete rate must be a number',
      )
      .optional(),
    providerTeam: z.object({
      name: z.string(),
      id: z.string(),
    }),
    activityStatus: z.string().min(1, 'Activity status is required'),
    delayDuration: z.string().length(8, 'Delay duration is not correct').optional(),
  })
  .superRefine((data, ctx) => {
    if (data.delayCategory.name && data.delayCategory.name !== 'No Delay' && !data.delayDuration) {
      ctx.addIssue({
        path: ['delayDuration'],
        message: 'Delay duration is required',
        code: z.ZodIssueCode.custom,
      });
    }
  });

export const editStatusSchema = z.object({
  dateRange: z.object({
    startDate: z.string().or(z.date()).or(z.null()),
    endDate: z.string().or(z.date()).or(z.null()),
  }),
  actualStart: z.string().or(z.date()).or(z.null()),
  actualEnd: z.string().or(z.date()).or(z.null()),
  providerTeam: z.object({
    name: z.string(),
    id: z.string(),
  }),
  activityStatus: z.string().min(1, 'Activity status is required'),
});

export const disconnectSchema = z.object({
  parent: z.string().min(1, 'Choose a parent activity'),
  child: z.string().min(1, 'Choose a child activity'),
});

export const addJobSchema = z.object({
  reportedBy: z.string().min(1, 'This field is required'),
  activityName: z.string().min(1, 'This field is required'),
  jobNumber: z.string().min(1, 'Enter job number'),
  jobName: z.string().min(1, 'Enter job number'),
  equipment: z.string().min(1, 'Enter equipment').optional(),
  type: z.object({
    name: z.string(),
    id: z.string(),
  }),
  startTime: z.string().or(z.date()).or(z.null()),
  duration: z.string(),
  endTime: z.string().or(z.date()).or(z.null()),
  providerTeam: z.object({
    name: z.string(),
    id: z.string(),
  }),
  provider: z.object({
    name: z.string(),
    id: z.string(),
  }),
  notes: z.string().optional(),
  dateRange: z.object({
    startDate: z.string(),
    endDate: z.string(),
  }),
});
