import { FC, Dispatch, SetStateAction, useMemo } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { Button } from 'src/shared/ui/button';
import { Icons } from 'src/assets/icons';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { TextField } from 'src/shared/ui/textField';
import { dayjs, not, showToastErrorMessage } from 'src/shared/utils';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { ActivityJob, ActivityTrack, WorkTrack } from 'src/shared/types';
import {
  useGetDelayCategoriesQuery,
  useGetProviderTeamsByIdQuery,
  usePostActivityStateMutation,
} from 'src/store/api/activities';
import { TimePickerInput } from 'src/shared/ui/timePickerInput';
import {
  formatTimePickerValueToHhMmSs,
  getTimePickerValueFromHhMmSs,
} from 'src/shared/ui/datepicker/helpers';

import { TextAreaInput } from '../textAreaInput';
import { ACTIVITY_STATUSES, addUpdateSchema, getWorkTrackValue } from '../../helpers';

interface AddUpdateModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedJob: ActivityJob;
  onJobUpdate: () => void;
  timeZone: string;
}

const AddUpdateModal: FC<AddUpdateModalProps> = ({
  isOpen,
  setIsOpen,
  selectedJob,
  onJobUpdate,
  timeZone,
}) => {
  const [postActivityState, { isLoading: isLoadingNewState }] = usePostActivityStateMutation();
  const { data: providerTeamValues } = useGetProviderTeamsByIdQuery({
    id: selectedJob.providerId,
  });
  const { data: delayCategories } = useGetDelayCategoriesQuery();

  const delayCategoriesVariants = useMemo(() => {
    const emptyState = {
      id: '',
      name: '',
    };

    const preparedDelayCategories = delayCategories
      ? delayCategories.map(({ DelayCategory, DelayCategoryID }) => ({
          name: DelayCategory,
          id: DelayCategoryID,
        }))
      : [];

    return [emptyState, ...preparedDelayCategories];
  }, [delayCategories]);

  const initialValues = useMemo(() => {
    return {
      notes: '',
      delayCategory: {
        id: selectedJob.delayCategoryID || '',
        name: selectedJob.delayCategoryName || '',
      },
      delayDuration: selectedJob.delayDuration || '',
      percentComplete: `${selectedJob.workProgress}`,
      activityStatus: selectedJob.workStatus,
      providerTeam: {
        id: selectedJob.providerTeamId,
        name: selectedJob.providerTeam.name,
      },
    };
  }, [selectedJob, timeZone]);

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data: z.infer<typeof addUpdateSchema>) => {
    const startDate = dayjs.utc(selectedJob.startWork).tz(timeZone).toISOString();
    const endDate = dayjs.utc(selectedJob.endWork).tz(timeZone).toISOString();

    try {
      await postActivityState({
        startDate,
        endDate,
        notes: data.notes,
        delayCategoryID: data.delayCategory.id,
        name: selectedJob.activityName,
        equipment: selectedJob.equipment,
        equipmentTypeId: selectedJob.equipmentTypeId,
        progress: Number(data.percentComplete),
        providerTeamId: data.providerTeam.id,
        providerId: selectedJob.providerId,
        activityId: selectedJob.id,
        health: selectedJob.workTrack,
        track: data.activityStatus as ActivityTrack,
        jobNumber: selectedJob.jobNumber,
        reportedBy: selectedJob.reportedBy,
        delayDuration: data.delayDuration,
      }).unwrap();
      onJobUpdate();
      closeModal();
    } catch (error) {
      showToastErrorMessage('There was an error trying to update activity');
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue, errors, dirty } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(addUpdateSchema),
    initialValues,
    enableReinitialize: true,
  });

  const delayDuration = getTimePickerValueFromHhMmSs(values.delayDuration) || '';

  const activityStatusItems = useMemo(
    () =>
      ACTIVITY_STATUSES.map((option) => ({
        label: (
          <SelectInputItem selected={values.activityStatus === option}>
            {getWorkTrackValue(option)}
          </SelectInputItem>
        ),
        value: option,
        onClick: () => {
          handleChange({
            target: {
              name: 'activityStatus',
              value: option,
            },
          });
        },
      })),
    [values.activityStatus, handleChange],
  );

  const providerTeamVariants = useMemo(() => {
    return (
      providerTeamValues || [
        {
          name: selectedJob.providerTeam.name,
          id: selectedJob.providerTeamId,
        },
      ]
    );
  }, [providerTeamValues, selectedJob]);

  const providerTeamItems = useMemo(
    () =>
      providerTeamVariants.map(({ id, name }) => ({
        label: (
          <SelectInputItem selected={values.providerTeam.name === name}>{name}</SelectInputItem>
        ),
        labelName: name,
        value: id,
        onClick: () => {
          handleChange({
            target: {
              name: 'providerTeam',
              value: {
                id,
                name,
              },
            },
          });
        },
      })),
    [providerTeamVariants, values.providerTeam, handleChange],
  );

  const delayCategoriesItems = useMemo(
    () =>
      delayCategoriesVariants?.map(({ name, id }) => ({
        label: <SelectInputItem selected={values.delayCategory.id === id}>{name}</SelectInputItem>,
        value: id,
        onClick: () => {
          handleChange({
            target: {
              name: 'delayCategory',
              value: {
                id,
                name,
              },
            },
          });
        },
      })),
    [delayCategoriesVariants, values.delayCategory, handleChange],
  );

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-full w-[646px]"
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Add Update
      </Typography>

      <form
        className="flex flex-col justify-between gap-y-6 min-h-[368x] min-w-[451px] w-full  "
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <div
            className={clsx(
              'flex flex-col gap-y-8 w-full max-h-[60vh] lg:max-h-[50vh] overflow-y-scroll pr-2',
            )}
          >
            <SelectInput
              isRequired
              required
              name="activityStatus"
              label="Activity Status"
              value={getWorkTrackValue(values.activityStatus)}
              placeholder={WorkTrack.SetStatus}
              items={activityStatusItems}
            />

            <SelectInput
              isRequired
              required
              name="providerTeam"
              label="Assign team"
              value={values.providerTeam.name}
              placeholder={selectedJob.providerTeam.name}
              disabled={!providerTeamValues}
              items={providerTeamItems}
            />

            <TextAreaInput
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'notes',
                    value,
                  },
                })
              }
              value={values.notes}
              label="Update Notes"
              placeholder="Add update notes"
              textLabelClassName="text-textColor-secondary font-semibold"
            />

            <div className="flex gap-8">
              <SelectInput
                name="delayCategory"
                label="Delay Type"
                value={values.delayCategory.name || ''}
                placeholder="Specify delay type"
                disabled={!delayCategories}
                onClear={() =>
                  setFieldValue('delayCategory', {
                    id: '',
                    name: '',
                  })
                }
                items={delayCategoriesItems}
              />

              <TimePickerInput
                label="Delay Duration"
                name="delayDuration"
                value={delayDuration}
                handleSelect={(name, value) =>
                  setFieldValue(name, formatTimePickerValueToHhMmSs(value))
                }
                onClear={(name) => setFieldValue(name, '')}
                isRequired={!!values.delayCategory.id}
              />
            </div>

            <div className="w-full lg:w-1/2">
              <TextField
                name="percentComplete"
                label="Estimated Percent Complete"
                placeholder="Specify estimated percent complete rate"
                type="number"
                value={values.percentComplete}
                disabled={selectedJob.workStatus === WorkTrack.Completed}
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: 'percentComplete',
                      value: value.target.value,
                    },
                  })
                }
                error={errors.percentComplete}
              />
            </div>
          </div>

          <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
            <Button
              type="button"
              variant="outlined"
              color="basic"
              size="lg"
              onClick={closeModal}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="lg"
              endIcon={<Icons.Filled.Chevrons.ChevronRight className="fill-white" />}
              autoFocus
              disabled={!dirty || isLoadingNewState}
            >
              Add update
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { AddUpdateModal };
