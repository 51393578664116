import { ActivityTrack } from 'src/shared/types';

export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const ONE_HOUR_WIDTH = 33;
export const ONE_HOUR_DAY_WIDTH = 61;
export const ONE_HOUR_WIDTH_BY_MINUTES = ONE_HOUR_WIDTH / MINUTES_IN_HOUR;
export const ONE_HOUR_DAY_WIDTH_BY_MINUTES = ONE_HOUR_DAY_WIDTH / MINUTES_IN_HOUR;
export const HOURS_IN_COLUMN = 3;
export const CARD_SPREAD_BUTTON_RADIUS = 16;
export const MAX_CARD_Z_INDEX = 997;
export const HEADER_HEIGHT = 72;
export const DATES_VERTICAL_GAP = 12;
export const DATES_TOP_SPACE = 12;
export const DAY_TITLE_HEIGHT = 24;
export const DAY_PADDING_BOTTOM = 56;

export const DEFAULT_SELECT_VALUE = {
  name: '',
  id: '',
};

export const DEFAULT_LOCATION_TIME_ZONE = 'America/Chicago';
export const DEFAULT_OWNER_LOCATION_ID = '1';

export const ACTIVITY_STATUSES: ActivityTrack[] = [
  'SetStatus',
  'Scheduled',
  'InProgress',
  'Completed',
];
