import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { Button } from 'src/shared/ui/button';
import { ReactComponent as UploadIcon } from 'src/assets/icons/filled/files/upload.svg';

const XLSX_TYPE = '.application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const UploadJobsButton: FC = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // TODO: Add selectedFile sending to server
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const validateFile = (file: File) => {
    const isNotValidTypeFile = file.type !== XLSX_TYPE;

    if (isNotValidTypeFile) {
      return;
    }

    setSelectedFile(file);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files?.length) {
      return;
    }

    const inputFile = event.target.files[0];

    validateFile(inputFile);
  };

  useEffect(() => {
    if (selectedFile) {
      // TODO: Add file sending to server
      setSelectedFile(null);
    }
  }, [selectedFile]);

  return (
    <div>
      <input
        id="downloadFile"
        type="file"
        accept={`.xlsx, ${XLSX_TYPE}`}
        onChange={handleFileChange}
        ref={inputRef}
        className="hidden"
      />

      <Button
        variant="filled"
        onClick={handleInputClick}
        className="w-[140px] bg-brandingColor-primary-gradient text-white"
        endIcon={<UploadIcon />}
        iconClassName="fill-white"
      >
        Upload Jobs
      </Button>
    </div>
  );
};

export { UploadJobsButton };
