import { match } from 'ts-pattern';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';

import { BoardLayout, TimePeriod } from 'src/shared/types';

import { DateRangeType, DateType, DatesStateRange } from '../ui/datepicker/types';
import { IS_AM_PM_FORMAT } from '../../config';

const DATE_FORMAT = {
  SURVEY: 'YYYY-MM-DD',
  PDF_FILENAME: 'YYYY_MM_DD_HH_mm',
} as const;

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(timezone);

const getWeekDays = (date: string) => {
  const currentDate = dayjs(date);

  return Array.from({ length: 7 }, (_, i) =>
    dayjs(currentDate).add(i, 'day').hour(12).format().toString(),
  );
};
const getSingleDay = (date: string) => {
  const currentDate = dayjs(date);
  const start = dayjs(currentDate).hour(12).format().toString();
  const end = dayjs(currentDate).hour(23).format().toString();

  return [start, end];
};

const getLocalMonthDays = (date: string) => {
  const currentDate = dayjs(date);
  const daysInMonth = dayjs(currentDate).daysInMonth();
  const monthStart = dayjs(currentDate).startOf('month').format().toString();

  return Array.from({ length: daysInMonth }, (_, i) =>
    dayjs(monthStart).add(i, 'day').format().toString(),
  );
};

const getDatesBetween = (dateRange: DateRangeType) => {
  const dates = [];
  let currentDate = dayjs(dateRange.startDate).utc();
  const end = dayjs(dateRange.endDate).utc();

  while (currentDate <= end) {
    dates.push(currentDate.format());
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

const getNextDayDate = (date: DateType) => {
  return dayjs(date).add(1, 'day').utc().format();
};

/**
 * @deprecated currently we work only with weeks.
 * This might be useful in future.
 */
const getMonthDays = (date: string) => {
  const currentDate = dayjs(date);
  const daysInMonth = dayjs(currentDate).daysInMonth();

  return Array.from({ length: daysInMonth }, (_, i) =>
    dayjs(currentDate).add(i, 'day').utc().format(),
  );
};

const getCurrentDay = () => {
  return dayjs().utc().format();
};

// | Can be used for createdAt and updatedAt
const getCurrentTimestamp = () => {
  return dayjs().utc().format();
};

const getFirstDayOfWeek = (date: string) => {
  const currentDate = dayjs(date);
  const isSunday = currentDate.day() === 0;
  const day = isSunday ? currentDate.subtract(2, 'day') : currentDate;

  return dayjs(day).weekday(1).utc().format();
};

const getRangeOfDatesBasedOnLayout = (date: string) => (layout: BoardLayout) => {
  return match(layout)
    .with('week', () => getWeekDays(date))
    .with('day', () => getSingleDay(date))
    .with('month', () => getLocalMonthDays(date))
    .otherwise(() => []);
};

const getPaginationDays = (dates: string[]) => {
  const startDate = dates.length ? new Date(dates[0]) : new Date();
  const endDate = dates.length ? new Date(dates[dates.length - 1]) : new Date();

  startDate.setUTCHours(0, 0, 0, 0);
  endDate.setUTCHours(0, 0, 0, 0);

  return [startDate.toISOString(), endDate.toISOString()];
};

const getPaginationRangeDays = (dateRange: DatesStateRange): [string, string] => {
  const formatToISODate = (dateStr: DateType): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    date.setUTCHours(0, 0, 0, 0);

    return date.toISOString();
  };

  const startDateISO = formatToISODate(dateRange.start.startDate);
  const endDateISO = formatToISODate(dateRange.end.endDate);

  return [startDateISO, endDateISO];
};

const getTimePickerOptions = (usedForDuration = false) => {
  let timePickerOptions = [];

  if (IS_AM_PM_FORMAT === 'true' && !usedForDuration) {
    timePickerOptions = Array.from({ length: 48 }, (_, i) => {
      const hour = Math.floor(i / 2) % 12 || 12;
      const period = Math.floor(i / 24) === 0 ? 'am' : 'pm';
      const minutes = i % 2 === 0 ? '00' : '30';
      return `${hour.toString().padStart(2, '0')}:${minutes} ${period}`;
    });
  } else {
    const hours = [...Array(24).keys()];
    const minutes = [0, 30];

    timePickerOptions = hours.flatMap((hour) => {
      return minutes.map((minute) => {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute === 0 ? '00' : '30';
        return `${formattedHour}:${formattedMinute}`;
      });
    });
  }

  return timePickerOptions;
};

const getFormattedTimePickerDate = (timeString: string) => {
  let resultDate: dayjs.Dayjs;

  if (IS_AM_PM_FORMAT === 'true') {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);

    resultDate = dayjs().hour(hours).minute(minutes).second(0).millisecond(0);

    if (period.toLowerCase() === 'pm' && hours !== 12) {
      resultDate = resultDate.add(12, 'hour');
    }

    if (period.toLowerCase() === 'am' && hours === 12) {
      resultDate = resultDate.subtract(12, 'hour');
    }
  } else {
    const [hours, minutes] = timeString.split(':').map(Number);
    resultDate = dayjs().hour(hours).minute(minutes).second(0).millisecond(0);
  }

  return resultDate;
};

const getFormattedTimePickerDateTime = (timestamp: string | undefined) => {
  if (!timestamp) return undefined;

  const format = IS_AM_PM_FORMAT === 'true' ? 'hh:mm A' : 'HH:mm';
  const parsedTimestamp = dayjs(timestamp).subtract(dayjs(timestamp).utcOffset(), 'minute');
  const time = parsedTimestamp.format(format).toLowerCase();

  return time;
};

const getFormattedTimePickerTime = (timestamp: string | undefined, usedForDuration = false) => {
  if (!timestamp) return undefined;

  const format = IS_AM_PM_FORMAT === 'true' && !usedForDuration ? 'hh:mm A' : 'HH:mm';
  const parsedTimestamp = dayjs(timestamp);
  const time = parsedTimestamp.utc().format(format).toLowerCase();

  return time;
};

const formatDate = (date: string, format = 'YYYY-MM-DD') => {
  const dayjsDate = dayjs(date);

  if (!dayjsDate.isValid()) {
    return date;
  }

  return dayjsDate.format(format);
};

type RecursiveFormat<Data> = (
  data: Data,
  dateFormat?: string,
) => Data extends string
  ? string
  : Data extends object
  ? {
      [Key in keyof Data]: RecursiveFormat<Data[Key]>;
    }
  : Data extends Array<infer Item>
  ? Array<RecursiveFormat<Item>>
  : Data;

const formatDateRecursively = <Data>(
  data: Data,
  dateFormat?: string,
): ReturnType<RecursiveFormat<Data>> => {
  if (Array.isArray(data)) {
    // @ts-expect-error too complicated type
    return data.map((item) => formatDateRecursively(item, dateFormat));
  }

  if (typeof data === 'object' && data !== null) {
    // @ts-expect-error too complicated type
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        return [key, formatDateRecursively(value, dateFormat)];
      }),
    );
  }

  if (typeof data === 'string' && Number.isNaN(Number(data))) {
    // @ts-expect-error too complicated type
    return formatDate(data, dateFormat);
  }

  // @ts-expect-error too complicated type
  return data;
};

const formatTicketDate = (date: string) => {
  return formatDate((date || '').split('T')[0]);
};

const getFormattedTime = (
  selectedTime: string | undefined,
  timeOptions: string[] = getTimePickerOptions(),
) => {
  const formattedTime =
    timeOptions.find((time) => time.replace(':', '') === selectedTime || time === selectedTime) ||
    '';

  return formattedTime;
};

const getEndTimeOptions = (startTime: string, duration: string) => {
  let endTime = '';
  let endTimeEndsTomorrow = false;

  if (startTime && duration) {
    const [hoursStart, minutesStart] =
      IS_AM_PM_FORMAT === 'true'
        ? startTime.split(' ')[0].split(':').map(Number)
        : startTime.split(':').map(Number);
    const [hoursDuration, minutesDuration] = duration.split(':').map(Number);

    const hoursEnd = hoursStart + hoursDuration;
    const minutesEnd = minutesStart + minutesDuration;

    const startDate = dayjs().hour(hoursStart).minute(minutesStart).second(0);
    const endDate = dayjs().hour(hoursEnd).minute(minutesEnd).second(0);

    endTimeEndsTomorrow = startDate.isBefore(endDate, 'day');
    endTime = endDate.format(IS_AM_PM_FORMAT === 'true' ? 'hh:mm A' : 'HH:mm').toLowerCase();
  }

  return {
    endTime,
    endTimeEndsTomorrow,
  };
};

const getWeekDaysFromMonday = (activeDate: string) => {
  const date = dayjs(activeDate);
  const dayOfWeek = date.day();
  const monday = date.subtract(dayOfWeek === 0 ? 6 : dayOfWeek, 'day').format();
  const weekDays = getRangeOfDatesBasedOnLayout(monday)(TimePeriod.Week);

  return weekDays;
};

export {
  dayjs,
  getMonthDays,
  getWeekDays,
  getSingleDay,
  getLocalMonthDays,
  getWeekDaysFromMonday,
  getDatesBetween,
  getCurrentDay,
  getRangeOfDatesBasedOnLayout,
  getFirstDayOfWeek,
  getCurrentTimestamp,
  getNextDayDate,
  getPaginationDays,
  getTimePickerOptions,
  getFormattedTimePickerDate,
  getFormattedTimePickerDateTime,
  getFormattedTimePickerTime,
  formatDate,
  formatDateRecursively,
  formatTicketDate,
  getFormattedTime,
  getEndTimeOptions,
  getPaginationRangeDays,
  DATE_FORMAT,
};
