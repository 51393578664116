import { Dispatch, FC, memo, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Model } from 'survey-core';
import { Tabulator, TableExtensions } from 'survey-analytics/survey.analytics.tabulator';
import { DocumentHelper } from 'survey-analytics';
import { selectCurrentUser } from 'src/store/slices';
import { getFormattedTimePickerTime, not } from 'src/shared/utils';
import { getFormattedDate } from 'src/shared/ui/details/helpers';
import { Role, SortBy, SortOrder, SurveyAnswerEntity, SurveyContent } from 'src/shared/types';
import { ADMIN_ROLES } from 'src/shared/constants';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.css';

const CONTAINER_ID = 'survey-answers-container';

interface IVisualizationPanelProps {
  form: SurveyContent | undefined;
  answers: SurveyAnswerEntity[];
  deleteAnswer: any;
  setIsDeleteSurveyAnswerModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedSurveyAnswer: Dispatch<SetStateAction<SurveyAnswerEntity | undefined>>;
  handleSortChange: (sort: SortBy) => void;
}

const VisualizationPanel: FC<IVisualizationPanelProps> = ({
  form,
  answers,
  deleteAnswer,
  setIsDeleteSurveyAnswerModalOpen,
  setSelectedSurveyAnswer,
  handleSortChange,
}) => {
  const navigate = useNavigate();
  const [vizPanel, setVizPanel] = useState<Tabulator>();
  const { formId } = useParams();

  const user = useSelector(selectCurrentUser);

  const userRole = user?.ProviderRoleMatrix?.userRole as Role | undefined;
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  useEffect(() => {
    if (not(form)) {
      return;
    }

    const survey = new Model(form.content);

    survey.pages[0].addNewQuestion('text', 'Created At');
    survey.pages[0].addNewQuestion('text', 'Updated At');

    const visualizationPanel = new Tabulator(
      survey as any,
      answers.map((answer) => ({
        ...answer.answer,
        'Created At': `${getFormattedDate(answer.createdAt)} ${getFormattedTimePickerTime(
          answer.createdAt,
        )}`,
        'Updated At': `${getFormattedDate(answer.updatedAt)} ${getFormattedTimePickerTime(
          answer.updatedAt,
        )}`,
      })),
      {
        tabulatorOptions: {
          pagination: false,
        },
      },
    );

    setVizPanel(visualizationPanel);
  }, [form, navigate, answers]);

  const handleSortClick = useCallback(
    (event: Event) => {
      let target = event.target as HTMLElement | null;
      while (target && !target.classList.contains('tabulator-col')) {
        target = target.parentElement;
      }

      if (target) {
        const timeStampFields = ['CreatedAt', 'UpdatedAt'];
        const columnName = target.getAttribute('tabulator-field')?.replaceAll(' ', '');
        const sortDirection = target.getAttribute('aria-sort');

        if (sortDirection && sortDirection !== 'none' && columnName) {
          const fieldKey = timeStampFields.includes(columnName)
            ? columnName[0].toLowerCase() + columnName.substring(1)
            : `answer.${columnName}`;

          handleSortChange({
            fieldKey,
            order: sortDirection as SortOrder,
          });
        }
      }
    },
    [handleSortChange],
  );

  useEffect(() => {
    if (vizPanel) {
      vizPanel.render(CONTAINER_ID);

      const cols = document.querySelectorAll(`#${CONTAINER_ID} .tabulator-col`);
      cols.forEach((header) => {
        header.addEventListener('click', handleSortClick);
      });

      const viewAnswerName = 'view-answer';
      const editAnswerName = 'edit-answer';
      const deleteAnswerName = 'delete-answer';

      TableExtensions.unregisterExtension('details', viewAnswerName);
      TableExtensions.unregisterExtension('details', editAnswerName);
      TableExtensions.unregisterExtension('details', deleteAnswerName);

      TableExtensions.registerExtension({
        location: 'details',
        name: viewAnswerName,
        visibleIndex: 1,
        render: (table, opt) => {
          const btn = DocumentHelper.createElement(
            'button',
            'sa-table__btn sa-table__btn--small mr-1 transition-all hover:opacity-[0.8] duration-200',
            {
              innerHTML: 'View',
              onclick: () => {
                if (not(form)) {
                  return;
                }

                const answer = answers[opt.row.getDataPosition()];
                navigate(`/forms/${formId}/answers/${answer.id}`);
              },
            },
          );

          return btn;
        },
      });

      if (not(isReadonly)) {
        TableExtensions.registerExtension({
          location: 'details',
          name: editAnswerName,
          visibleIndex: 1,
          render: (table, opt) => {
            const btn = DocumentHelper.createElement(
              'button',
              'sa-table__btn sa-table__btn--small mr-1 transition-all hover:opacity-[0.8] duration-200',
              {
                innerHTML: 'Edit',
                onclick: () => {
                  if (not(form)) {
                    return;
                  }

                  const answer = answers[opt.row.getDataPosition()];
                  navigate(`/forms/${formId}/answers/${answer.id}/edit`);
                },
              },
            );

            return btn;
          },
        });

        if (!userRole || !ADMIN_ROLES.includes(userRole)) {
          return;
        }

        TableExtensions.registerExtension({
          location: 'details',
          name: deleteAnswerName,
          visibleIndex: 1,
          render: (table, opt) => {
            const btn = DocumentHelper.createElement(
              'button',
              'sa-table__btn sa-table__btn--small border border-semanticColor-danger text-white !bg-semanticColor-danger transition-all hover:opacity-[0.8] duration-200',
              {
                innerHTML: 'Delete',
                onclick: async () => {
                  if (not(form)) {
                    return;
                  }

                  const answer = answers[opt.row.getDataPosition()];

                  setIsDeleteSurveyAnswerModalOpen(true);
                  setSelectedSurveyAnswer(answer);
                },
              },
            );
            return btn;
          },
        });
      }
    }
  }, [deleteAnswer, form, formId, isReadonly, navigate, userRole, vizPanel, answers]);

  return (
    <div
      id={CONTAINER_ID}
      className="w-full h-[70%]"
    />
  );
};

const MemoizedVisualizationPanel = memo(VisualizationPanel);

export { MemoizedVisualizationPanel as VisualizationPanel };
