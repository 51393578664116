import { FC } from 'react';
import { useSelector } from 'react-redux';

import { showToastErrorMessage, not } from 'src/shared/utils';
import {
  type PersonEntity,
  type JobEntity,
  type TicketEntity,
  LaborEntity,
  Error,
  Role,
} from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { PeopleList } from 'src/shared/ui/peopleList';
import { ReactComponent as PlusIcon } from 'src/assets/icons/filled/edit/plus.svg';
import { IconButton } from 'src/shared/ui/iconButton';
import { useAssignToTicketMutation, TicketConnections } from 'src/store/api';
import { useAppDispatch } from 'src/store';
import {
  OpenModalId,
  OpenModalIdType,
  modalConfigActions,
  selectCurrentUser,
} from 'src/store/slices';

type TicketCardPeopleListProps = {
  ticket: TicketEntity;
  job: JobEntity;
  isTicketDropDisabled: boolean;
};

const TicketCardPeopleList: FC<TicketCardPeopleListProps> = ({
  ticket,
  job,
  isTicketDropDisabled,
}) => {
  const dispatch = useAppDispatch();

  const [assignToTicket] = useAssignToTicketMutation();

  const user = useSelector(selectCurrentUser);
  const isReadonly = user?.ProviderRoleMatrix?.userRole === Role.SurveyReadonly;

  const ticketPeople = (
    ticket.labor?.flatMap((person) => person.ProviderPersonnel).filter(Boolean) as PersonEntity[]
  ).sort((a, b) => `${b.firstName} ${b.lastName}`.length - `${a.firstName} ${a.lastName}`.length);

  const crewLeader = ticket?.crewLeader;

  const openCreateAdditionalItemsModal = (modalId: OpenModalIdType) => {
    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(ticket.id));
    dispatch(modalConfigActions.setOpenModalId(modalId));
  };

  const handleEmployeeDrop = async (person: PersonEntity, sourceEntityId: string) => {
    try {
      await assignToTicket({
        ticketId: ticket.id,
        entityType: TicketConnections.person,
        entityId: person.id,
        sourceEntityId,
      }).unwrap();
    } catch (error) {
      const errorText =
        (error as Error).status === 400
          ? `Cannot assign ${person.firstName} ${person.lastName} due to overlapping timeframe`
          : `Sorry, an error occurred, when you tried to assign ${person.firstName} ${person.lastName} for a ticket, please check your internet connection`;

      showToastErrorMessage(errorText);
    }
  };

  const handleCrewLeaderDrop = async (person: LaborEntity, sourceEntityId: string) => {
    try {
      await assignToTicket({
        ticketId: ticket.id,
        entityType: TicketConnections.crewLeader,
        entityId: person!.ProviderPersonnel!.id,
        sourceEntityId,
      }).unwrap();
    } catch (error) {
      const { ProviderPersonnel: crewLeader } = person;

      const errorText =
        (error as Error).status === 400
          ? `Cannot assign ${crewLeader?.firstName} ${crewLeader?.lastName} due to overlapping timeframe`
          : `Sorry, an error occurred, when you tried to assign ${crewLeader?.firstName} ${crewLeader?.lastName} for a ticket, please check your internet connection`;

      showToastErrorMessage(errorText);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography
          variant="p2"
          fontWeight="medium"
        >
          Labor
        </Typography>

        {not(isTicketDropDisabled) && not(isReadonly) && (
          <IconButton
            color="basic"
            size="sm"
            iconSize="sm"
            className="bg-bgColor-ticketCard"
            iconClassName="fill-textColor-tertiary"
            onClick={() => openCreateAdditionalItemsModal(OpenModalId.CreateLabor)}
          >
            <PlusIcon />
          </IconButton>
        )}
      </div>

      <PeopleList
        crewLeader={crewLeader}
        people={ticketPeople}
        onEmployeeDrop={handleEmployeeDrop}
        onCrewLeaderDrop={handleCrewLeaderDrop}
        cardId={job?.id}
        ticket={ticket}
        isDropDisabled={isTicketDropDisabled}
        isTicketCard
      />
    </>
  );
};

TicketCardPeopleList.displayName = 'TicketCardPeopleList';
export { TicketCardPeopleList };
