import { ProviderPortal } from 'src/shared/types';
import { BoardFilters, SidebarFilters, api } from 'src/store/api/api';

type Filter = { label: string; options: string[] };
type GetBoardFiltersParams = Pick<BoardFilters, 'providerArea' | 'providerRegion'>;

type GetSidebarFiltersParams = Pick<
  SidebarFilters,
  'providerAreaSidebar' | 'providerRegionSidebar'
>;

const configurationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPortalProviderSettings: builder.query({
      query: () => '/configuration/portal',
      providesTags: ['Configuration'],
      transformResponse: (response: { data: ProviderPortal }) => response.data,
    }),

    getBoardFilters: builder.query<Filter[], GetBoardFiltersParams>({
      query: ({ providerArea, providerRegion }) => ({
        url: '/configuration/filters/board',
        params: {
          providerArea: providerArea?.length ? providerArea?.join(',') : [],
          providerRegion: providerRegion?.length ? providerRegion?.join(',') : [],
        },
      }),
      providesTags: ['Configuration'],
      transformResponse: (response: { data: { label: string; options: string[] }[] }) =>
        response.data,
    }),

    getSidebarFilters: builder.query<Filter[], GetSidebarFiltersParams>({
      query: ({ providerAreaSidebar, providerRegionSidebar }) => ({
        url: '/configuration/filters/sidebar',
        params: {
          providerArea: providerAreaSidebar?.length ? providerAreaSidebar?.join(',') : [],
          providerRegion: providerRegionSidebar?.length ? providerRegionSidebar?.join(',') : [],
        },
      }),
      providesTags: ['Configuration'],
      transformResponse: (response: { data: { label: string; options: string[] }[] }) =>
        response.data,
    }),

    getSurveyAnswersFilters: builder.query<Filter[], any>({
      query: () => ({
        url: '/configuration/filters/survey-answers',
      }),
      providesTags: ['Configuration'],
      transformResponse: (response: { data: { label: string; options: string[] }[] }) =>
        response.data,
    }),

    getActivityProcessesFilters: builder.query<Filter[], void>({
      query: () => ({
        url: '/configuration/filters/activity-provider-teams',
      }),
      transformResponse: (response: { data: { label: string; options: string[] }[] }) =>
        response.data,
    }),
  }),
});

export const {
  useGetPortalProviderSettingsQuery,
  useGetBoardFiltersQuery,
  useGetSidebarFiltersQuery,
  useGetSurveyAnswersFiltersQuery,
  useGetActivityProcessesFiltersQuery,
} = configurationApi;
