import { ReactNode, MouseEvent } from 'react';

import { clsx, not } from 'src/shared/utils';
import { PaginationType, SortOrder } from 'src/shared/types';
import { ReactComponent as DoubleChevron } from 'src/assets/icons/filled/chevrons/double-chevron.svg';
import { ReactComponent as ChevronDownIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';

import { Pagination } from '../pagination';
import { Typography } from '../typography';
import { Spinner } from '../spinner';
import { Icon } from '../icon';

import { SkeletonTable } from './ui/skeletonTable';

type TableHeaderType<T> = {
  title: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  field: Extract<keyof T, string> | (string & {});
  className?: string;
  canSort?: boolean;
  render?: (item: T) => ReactNode | string;
};

type TableProps<T> = {
  headers: TableHeaderType<T>[];
  data: T[];
  sortSettings?: {
    key: keyof T | null;
    order: SortOrder;
  };
  withIndicator?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  variant?: 'primary' | 'secondary';
  isError?: boolean;
  scroll?: boolean;
  sticky?: boolean;
  highlight?: string;
  containerClassName?: string;
  pagination?: PaginationType;
  onSort?: (sortSetting: { key: keyof T | null; order: SortOrder }) => void;
  onRowClick?: (event: MouseEvent<HTMLTableRowElement>, item: T) => void;
  idProvider?: (item: T) => string;
  getRowClassName?: (item: T) => string;
};

const Table = <T extends Record<string, any>>({
  headers,
  data,
  sortSettings,
  variant = 'primary',
  isLoading,
  isFetching,
  isError = false,
  withIndicator = false,
  scroll = false,
  sticky = false,
  highlight = '',
  containerClassName,
  pagination,
  onSort,
  idProvider,
  onRowClick,
  getRowClassName,
}: TableProps<T>) => {
  const tableContainerClassName = clsx(
    variant === 'primary' && 'border border-[#C1C4C9]',
    variant === 'secondary' && 'border-none',
    containerClassName,
  );

  const tableHeaderClassName = clsx(
    'border text-left py-2 px-3 text-xs',
    variant === 'primary' && 'border-[#C1C4C9]',
    variant === 'secondary' && 'border-r border-[#E4E9F2] bg-bgColor-menu text-textColor-tertiary',
  );

  const tableRowClassName = clsx(
    variant === 'primary' && 'bg-white',
    variant === 'secondary' && 'bg-[#FAFAFA]',
  );

  const tableCellClassName = clsx(
    variant === 'primary' && 'border-[#C1C4C9] text-[#42454A]',
    variant === 'secondary' && 'border-[#E4E9F2] text-textColor-primary',
  );

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, item: T) => {
    if (onRowClick) {
      onRowClick(e, item);
    }
  };

  const getHighlightedText = (text: string, highlight: string) => {
    if (!text) return null;

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    return (
      <span>
        {parts.map((part, i) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase() ? { backgroundColor: '#F0CC96' } : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  const getCellContent = (header: TableHeaderType<T>, item: T) => {
    if (header.render) {
      const isString = typeof header.render(item) === 'string';

      return isString && highlight
        ? getHighlightedText(header.render(item) as string, highlight)
        : header.render(item);
    }

    if (header.field) {
      return getHighlightedText(item[header.field], highlight);
    }

    return '-';
  };

  const handleSort = (header: TableHeaderType<T>) => {
    if (not(header.canSort) || not(sortSettings) || not(onSort)) return;

    const isSelected = header.field === sortSettings.key;

    if (isSelected && sortSettings.order === SortOrder.DESC) {
      onSort({
        key: header.field,
        order: SortOrder.ASC,
      });
    }

    if (isSelected && sortSettings.order === SortOrder.ASC) {
      onSort({
        key: null,
        order: SortOrder.DESC,
      });
    }

    if (not(isSelected)) {
      onSort({
        key: header.field,
        order: SortOrder.DESC,
      });
    }
  };

  if (isLoading) {
    return (
      <SkeletonTable
        containerClassName={containerClassName}
        withPagination={!!pagination}
      />
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center w-full">
        <div className="flex flex-col gap-2 text-center w-[525px]">
          <Typography variant="h3">There was an error trying to load data in the table.</Typography>

          <Typography variant="p1">
            Try reloading the page, or check your internet connection.
          </Typography>
        </div>
      </div>
    );
  }

  if (not(data.length) && withIndicator) {
    return (
      <div className="flex w-full h-full items-center justify-center ">
        <Typography
          variant="h3"
          className="max-w-[525px] text-center"
        >
          There is no data in the table
        </Typography>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col">
      {isFetching && data.length && <Spinner withBackdrop />}

      <div
        className={clsx('w-full bg-white', scroll && 'overflow-y-scroll', tableContainerClassName)}
      >
        <table className="w-full border-collapse table-auto">
          <thead className={clsx(sticky && 'sticky top-0 z-[10]')}>
            <tr className="bg-gray-200">
              {headers.map((header) => {
                const isSorted = !!sortSettings && sortSettings.key === header.field;
                const filterIcon = isSorted ? (
                  <Icon
                    size="sm"
                    icon={<ChevronDownIcon />}
                    className={clsx(
                      'fill-textColor-tertiary',
                      isSorted && sortSettings.order === SortOrder.ASC && 'rotate-180',
                    )}
                  />
                ) : (
                  <DoubleChevron />
                );

                return (
                  <th
                    key={header.title}
                    className={clsx(
                      tableHeaderClassName,
                      header.className,
                      sortSettings && header.canSort && onSort && 'cursor-pointer',
                    )}
                    onClick={() => handleSort(header)}
                  >
                    <div className="flex items-center gap-1">
                      {header.title}

                      {header.canSort && filterIcon}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => (
              <tr
                key={idProvider ? idProvider(item) : item.id}
                className={clsx(
                  tableRowClassName,
                  index % 2 !== 0 && 'bg-[#F5F5F5]',
                  onRowClick && 'hover:bg-[#E0E2E5] cursor-pointer',
                  getRowClassName && getRowClassName(item),
                )}
                onClick={(e) => handleRowClick(e, item)}
              >
                {headers.map((header) => (
                  <td
                    key={header.field}
                    className={clsx(
                      'border py-2 px-3 text-xs',
                      tableCellClassName,
                      header.className,
                    )}
                  >
                    {getCellContent(header, item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {pagination && !!data.length && <Pagination pagination={pagination} />}
    </div>
  );
};

export { Table };
export type { TableHeaderType };
