import { FC, Dispatch, SetStateAction } from 'react';

import { MetadataMap } from 'src/shared/types';

import { CreateToolbar } from './CreateToolbar';
import { FilterPanel } from './FilterPanel';
import { MapView } from './MapView';

interface IToolbarDataGridProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  searchField: string;
  isFetching: boolean;
  handleChange: (value: string) => void;
  fieldsMetadata: MetadataMap;
}

const ToolbarDataGrid: FC<IToolbarDataGridProps> = (props) => {
  const { fieldsMetadata } = props;
  return (
    <div className="flex gap-2 justify-between flex-wrap bg-white w-full py-2 px-4 items-center min-h-16">
      <CreateToolbar {...props} />

      <div className="flex gap-2 justify-end flex-1 items-center flex-wrap">
        {fieldsMetadata?.toolbar?.showSearch && <FilterPanel {...props} />}
        {fieldsMetadata?.toolbar?.showMapBtn && <MapView />}
      </div>
    </div>
  );
};

export { ToolbarDataGrid };
