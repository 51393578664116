import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from 'src/store';

export enum TypeAdminPageModal {
  Details = 'details',
  Create = 'create',
  Update = 'update',
}

type CreateModalValues = {
  [key: string]: {
    field: string;
    value: string;
    modelName: string;
  };
};

type InitialState = {
  table: {
    selectedRowId: string | number | null;
    typeModal: TypeAdminPageModal | null;
  };
  createModal: CreateModalValues | null;
};

const initialState: InitialState = {
  table: {
    selectedRowId: null,
    typeModal: null,
  },
  createModal: null,
};

const adminTableSlice = createSlice({
  name: 'adminTable',
  initialState,
  reducers: {
    selectRow: (
      state,
      action: PayloadAction<{
        id: string | number | null;
        typeModal: TypeAdminPageModal | null;
      }>,
    ) => {
      state.table = {
        selectedRowId: action.payload.id,
        typeModal: action.payload.typeModal,
      };
    },
    updateCreateModalValues: (
      state,
      action: PayloadAction<{
        [key: string]: {
          field: string;
          value: string;
          modelName: string;
        };
      } | null>,
    ) => {
      state.createModal = action.payload;
    },
  },
});

const adminTableReducer = adminTableSlice.reducer;
const adminTableActions = adminTableSlice.actions;
const selectTableState = (state: RootState) => state.adminTable.table;
const selectCreateModalState = (state: RootState) => state.adminTable.createModal;

export { adminTableReducer, adminTableActions, selectTableState, selectCreateModalState };
