import React, { ReactSVGElement, useRef, useState, FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as MinusSquareIcon } from 'src/assets/icons/filled/edit/minus-square.svg';
import { ReactComponent as EmptySquareIcon } from 'src/assets/icons/outlined/edit/square.svg';
import { ReactComponent as CheckedSquareIcon } from 'src/assets/icons/filled/edit/checkmark-square.svg';
import { ReactComponent as RadioButtonIconOn } from 'src/assets/icons/filled/controls/radio-button-on.svg';
import { ReactComponent as RadioButtonIconOff } from 'src/assets/icons/filled/controls/radio-button-off.svg';
import { ReactComponent as CollapseIcon } from 'src/assets/icons/filled/chevrons/chevron-down.svg';
import { ReactComponent as ExpandIcon } from 'src/assets/icons/filled/chevrons/chevron-up.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/filled/edit/close.svg';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { RootState, useAppDispatch } from 'src/store';
import { filtersActions } from 'src/store/slices/filters';

import { Icon } from '../icon';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { Tag } from '../tag';
import { clsx } from '../../utils';

type Option = {
  label: string;
  value: string;
};

type GroupedOption = {
  label: string;
  options: Option[];
};

type FilterSelectProps = {
  options: (Option | GroupedOption)[];
  label: string;
  placeholder: string;
  id: string;
  type: 'board' | 'sidebar';
  disabled?: boolean;
  isSingle?: boolean;
  onRemoveAllClick?: () => void;
  onOptionSelect?: () => void;
};

const FilterSelect: FC<FilterSelectProps> = ({
  options,
  label,
  placeholder,
  id,
  type,
  disabled = false,
  isSingle = false,
  onRemoveAllClick,
  onOptionSelect,
}) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [openGroups, setOpenGroups] = useState<string[]>([]);
  const filterSelectRef = useRef<HTMLDivElement>(null);

  const { selectedFilters } = useSelector((state: RootState) => state.filters[type]);
  const selectedValues = selectedFilters.filter((el) => el.id === id).map((el) => el.value);

  const groupedSelectedValues = selectedValues.reduce((acc: { [key: string]: string[] }, value) => {
    const filteredOptions = options.filter((option) => 'options' in option);

    const parent = filteredOptions.find((option) =>
      'options' in option ? option.options.some((el) => el.value === value) : false,
    ) || { label: '' };

    acc[parent.label] = acc[parent.label] ? [...acc[parent.label], value] : [value];

    return acc;
  }, {});

  const toggleGroup = (groupLabel: string) => {
    setOpenGroups((prevGroups) =>
      prevGroups.includes(groupLabel)
        ? prevGroups.filter((group) => group !== groupLabel)
        : [...prevGroups, groupLabel],
    );
  };

  const toggleValue = (value: string) => {
    dispatch(
      filtersActions.toggleValue({
        type,
        value,
        id,
      }),
    );
  };

  const removeSelectedValue = (e: MouseEvent<ReactSVGElement>, value: string) => {
    e.stopPropagation();
    toggleValue(value);

    if (onRemoveAllClick) {
      onRemoveAllClick();
    }
  };

  const toggleOptionValues = (option: GroupedOption) => {
    const optionValues = option.options.map((option) => option.value);
    dispatch(
      filtersActions.toggleOptionValues({
        type,
        optionValues,
        id,
      }),
    );
  };

  const removeAllSelectedValues = (e: MouseEvent<ReactSVGElement>) => {
    e.stopPropagation();
    dispatch(
      filtersActions.removeFiltersById({
        type,
        id,
      }),
    );

    if (onRemoveAllClick) {
      onRemoveAllClick();
    }
  };

  const hideFilterSelect = () => {
    setIsOpen(false);
  };

  useOutsideClick(filterSelectRef, hideFilterSelect, isOpen);

  const filledIcon = isSingle ? <RadioButtonIconOn /> : <CheckedSquareIcon />;
  const unfilledIcon = isSingle ? <RadioButtonIconOff /> : <EmptySquareIcon />;

  const toggleOption = (value: string) => {
    if (isSingle) {
      setIsOpen(false);
      dispatch(
        filtersActions.removeFiltersById({
          type,
          id,
        }),
      );

      toggleValue(value);
    } else {
      toggleValue(value);
    }

    if (onOptionSelect) {
      onOptionSelect();
    }
  };

  const getParentOption = (option: GroupedOption) => (
    <div key={option.label}>
      <div className="flex justify-between items-center gap-2 pb-2 w-full">
        <div>
          <Icon
            icon={
              option.options.some((opt) => selectedValues.includes(opt.value)) ? (
                <MinusSquareIcon />
              ) : (
                <EmptySquareIcon />
              )
            }
            onClick={() => toggleOptionValues(option)}
            className="cursor-pointer fill-brandingColor-primary-gradient"
          />
        </div>

        <button
          type="button"
          className="flex items-center justify-between w-full"
          onClick={() => toggleGroup(option.label)}
        >
          <div className="text-gray-800 font-medium">{option.label}</div>

          <Icon
            icon={openGroups.includes(option.label) ? <ExpandIcon /> : <CollapseIcon />}
            className="fill-bgColor-[#231F20]"
          />
        </button>
      </div>

      {openGroups.includes(option.label) && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <div className="pl-6 pt-1">{renderOptions(option.options)}</div>
      )}
    </div>
  );

  const getChildOption = (option: Option) => (
    <button
      type="button"
      key={option.value}
      className="flex items-center pb-2 gap-2 w-full cursor-pointer text-left"
      onClick={() => toggleOption(option.value)}
    >
      <Icon
        icon={selectedValues.includes(option.value) ? filledIcon : unfilledIcon}
        className="fill-brandingColor-primary-gradient min-w-8"
      />

      <div className="text-gray-800 font-medium">{option.label}</div>
    </button>
  );

  const renderOptions = (options: (Option | GroupedOption)[]) => {
    return options.map((option) =>
      'options' in option ? getParentOption(option) : getChildOption(option),
    );
  };

  const renderSelectedOptions = () => {
    return Object.keys(groupedSelectedValues).map((parent) => {
      const allValues = groupedSelectedValues[parent];
      const parentItemsSelected = groupedSelectedValues[parent].length;
      const parentElement = options.find((option) => option.label === parent);
      let parentItemsTotal = 0;

      if (parentElement && 'options' in parentElement) {
        parentItemsTotal = parentElement.options.length;
      }

      return (
        <div
          key={parent}
          className="flex flex-col gap-2"
        >
          {parentElement && (
            <div className="flex gap-2">
              {parent}

              <Tag
                type="info"
                className="text-brandingColor-primary-gradient bg-[rgba(23,78,186,0.2)]"
              >
                {`${parentItemsSelected} / ${parentItemsTotal}`}
              </Tag>
            </div>
          )}

          <div className="flex flex-wrap gap-1">
            {allValues.map((value) => (
              <div
                key={value}
                className="w-fit flex items-center justify-between gap-2.5 border bg-brandingColor-primary-gradient pl-[18px] pr-2 py-2 rounded-[25px] border-solid border-brandingColor-primary text-white"
              >
                <span>{value}</span>

                <Icon
                  onClick={(e) => removeSelectedValue(e, value)}
                  icon={<CloseIcon />}
                  className={clsx(
                    'fill-bgColor-main transition-all duration-200',
                    !disabled && 'hover:scale-[1.2] cursor-pointer',
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="relative"
      ref={filterSelectRef}
    >
      <span
        className={clsx(
          'min-w-[400px] pb-2 font-semibold text-base text-textColor-secondary',
          disabled && 'opacity-70',
        )}
      >
        {label}
      </span>

      <button
        type="button"
        className={clsx(
          'flex items-center justify-between w-full pl-3 pr-3 py-3 border bg-[rgba(99,155,221,0.06)] text-text-textColor-primary text-base rounded-xl border-solid mb-4',
          disabled && 'opacity-70',
        )}
        disabled={disabled}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex flex-col gap-4 flex-wrap w-4/5 text-left">
          {selectedValues.length > 0 ? renderSelectedOptions() : placeholder}
        </div>

        <div className="flex items-center justify-between gap-[10px]">
          {!!selectedValues.length && (
            <Icon
              icon={<CloseCircleIcon />}
              className={clsx(
                'fill-bgColor-[#231F20] transition-transform duration-200',
                !disabled && 'hover:scale-[1.2]',
              )}
              onClick={removeAllSelectedValues}
            />
          )}

          <Icon
            icon={isOpen ? <ExpandIcon /> : <CollapseIcon />}
            className={clsx(
              'fill-bgColor-[#231F20] transition-transform duration-200',
              !disabled && 'hover:scale-[1.5]',
            )}
          />
        </div>
      </button>

      {isOpen && (
        <div className="absolute z-[20] pl-3 pr-3 py-3 border w-full bg-[#F3F6FA] text-textColor-primary rounded-xl border-solid border-textColor-light">
          {renderOptions(options)}
        </div>
      )}
    </div>
  );
};

export { FilterSelect };
export type { GroupedOption };
