import { ADMIN_ROLES } from '../constants';
import { ProviderPortalSection, ProviderPortalSectionItem, Role } from '../types';

const getIsSectionItemClickable = (item: ProviderPortalSectionItem, userRole: Role) => {
  // show all the sections until we implement a new permissions system
  return true;

  const isSurveyRole = [Role.SurveyUser, Role.SurveyReadonly].includes(userRole);

  const isContainFormItems = item.Title.toLowerCase().includes('form');
  const isContainSupportItems = item.Title.toLowerCase().includes('support');

  if (isSurveyRole) {
    return isContainFormItems || isContainSupportItems;
  }

  return true;
};

const getIsPortalSectionItemVisible = ({
  section,
  item,
  userRole,
}: {
  section: ProviderPortalSection;
  item: ProviderPortalSectionItem;
  userRole: Role;
}) => {
  // show all the sections until we implement a new permissions system
  return true;

  const isAppSection = section.Title.toLowerCase().includes('app');

  if (isAppSection) {
    return true;
  }

  return getIsSectionItemClickable(item, userRole);
};

const getIsPortalSectionVisible = (section: ProviderPortalSection, userRole: Role) => {
  const isAdminSection = section.Title.toLowerCase().includes('admin');

  const isContainVisibleItems = section.ProviderPortalSectionItems.some((item) =>
    getIsPortalSectionItemVisible({
      section,
      item,
      userRole,
    }),
  );

  if (isAdminSection) {
    return ADMIN_ROLES.includes(userRole);
  }

  return isContainVisibleItems;
};

export { getIsPortalSectionItemVisible, getIsPortalSectionVisible, getIsSectionItemClickable };
