import { ActivityFilters } from 'src/shared/types';
import { api } from 'src/store/api/api';

interface ParamsById {
  id: string;
}
interface ProviderTeam {
  id: string;
  name: string;
  providerId: string;
}
interface Provider {
  ProviderID: string;
  ProviderName: string;
}
interface Process {
  id: string;
  activity: Activity[];
}

interface DelayCategory {
  DelayCategoryID: string;
  DelayCategory: string;
}

interface EquipmentType {
  EquipmentType: string;
  EquipmentTypeID: string;
}

export interface ActivityState {
  id: string;
  name: string;
  reportedBy: string;
  jobNumber: string;
  activityId: string;
  progress: number;
  providerId: string;
  provider: Provider;
  providerTeamId: string;
  providerTeam: ProviderTeam;
  notes?: string;
  delayCategoryID?: string;
  delayCategory?: {
    DelayCategory: string;
    DelayCategoryID: string;
  };
  equipment?: string;
  equipmentTypeId?: string;
  startDate: string;
  endDate: string;
  health: 'OnTrack' | 'Delayed' | 'OnHold';
  track: 'SetStatus' | 'Scheduled' | 'InProgress' | 'Completed';
  createdAt: string;
  delayDuration: string;
}

interface CreateNewActivity {
  teamId: string;
  ownerLocationID: string;
  id?: string;
}

interface CreateNewState {
  reportedBy: string;
  jobNumber: string;
  jobName: string;
  progress: number;
  providerId: string;
  providerTeamId: string;
  startDate: string;
  endDate: string;
  health: 'OnTrack' | 'Delayed' | 'OnHold';
  track: 'SetStatus' | 'Scheduled' | 'InProgress' | 'Completed';
  name?: string;
  notes?: string;
  delay?: string;
  delayCategoryID?: string;
  equipment?: string;
  equipmentTypeId?: string;
}

type ActivityWithState = CreateNewActivity & CreateNewState;

interface ActivityStateBody {
  name: string;
  reportedBy: string;
  jobNumber: string;
  activityId: string;
  progress: number;
  providerId: string;
  providerTeamId: string;
  notes?: string;
  delayCategoryID?: string;
  equipment?: string;
  equipmentTypeId?: string;
  startDate: string;
  endDate: string;
  health: 'OnTrack' | 'Delayed' | 'OnHold';
  track: 'SetStatus' | 'Scheduled' | 'InProgress' | 'Completed';
  delayDuration?: string;
}
export interface Activity {
  id: string;
  activityStates: ActivityState[];
  ownerLocationID: string;
  OwnerLocation: {
    LocationTimezone: string;
    OwnerName: string;
  };
  team: {
    id: string;
    name: string;
    providerId: string;
  };
  actualState: ActivityState;
}

interface ActivityByIdParams {
  id: string;
}
interface ProcessByIdParams extends ActivityFilters {
  id: string;
  startDate: string;
  endDate: string;
}
interface ProcessesParams extends ActivityFilters {
  startDate: string;
  endDate: string;
}

const activitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActivitiesByProviderTeamId: builder.mutation<Process, ProcessByIdParams>({
      query: ({ id, startDate, endDate, ...restFilters }) => ({
        url: `activities/team-providers/${id}`,
        params: {
          startDate,
          endDate,
          ...restFilters,
        },
      }),
      transformResponse: (response: { data: Process }) => response.data,
    }),
    getActivityById: builder.query<Activity, ActivityByIdParams>({
      query: ({ id }) => ({
        url: `activities/${id}`,
      }),
      transformResponse: (response: { data: Activity }) => response.data,
    }),
    postActivityState: builder.mutation<ActivityState, ActivityStateBody>({
      query: (newState) => ({
        url: 'activities/create-state',
        method: 'POST',
        body: newState,
      }),
      transformResponse: (response: { data: ActivityState }) => response.data,
      invalidatesTags: ['Activities'],
    }),
    getActivityProviders: builder.query<Provider[], void>({
      query: () => ({
        url: 'activities/providers',
      }),
      transformResponse: (response: { data: Provider[] }) => response.data,
    }),
    getActivityProviderTeams: builder.query<ProviderTeam[], void>({
      query: () => ({
        url: 'activities/team-providers-list',
      }),
      transformResponse: (response: { data: ProviderTeam[] }) => response.data,
    }),
    getProviderTeamsById: builder.query<ProviderTeam[], ParamsById>({
      query: ({ id }) => ({
        url: `activities/provider-teams/${id}`,
      }),
      transformResponse: (response: { data: ProviderTeam[] }) => response.data,
    }),
    getActivitiesByProviderTeams: builder.query<Process[], ProcessesParams>({
      query: (params) => ({
        url: 'activities/team-providers',
        params: { ...params },
      }),
      transformResponse: (response: { data: Process[] }) => response.data,
      providesTags: ['Activities'],
    }),
    getDelayCategories: builder.query<DelayCategory[], void>({
      query: () => ({
        url: 'activities/delay-categories',
      }),
      transformResponse: (response: { data: DelayCategory[] }) => response.data,
    }),

    getEquipmentTypes: builder.query<EquipmentType[], void>({
      query: () => ({
        url: 'activities/equipment-types',
      }),
      transformResponse: (response: { data: EquipmentType[] }) => response.data,
    }),
    postNewActivityByProviderTeam: builder.mutation<ActivityWithState | string, ActivityWithState>({
      query: (newState) => ({
        url: 'activities/create-activity',
        method: 'POST',
        body: newState,
      }),
      transformResponse: (response: { data: ActivityWithState | string }) => response.data,
      invalidatesTags: (result, error) => {
        if (typeof result === 'string' || error) {
          return [];
        }
        return ['Activities'];
      },
    }),
  }),
});

export const {
  useGetActivitiesByProviderTeamIdMutation,
  usePostActivityStateMutation,
  useGetActivityProvidersQuery,
  useGetActivityProviderTeamsQuery,
  useGetActivitiesByProviderTeamsQuery,
  useGetDelayCategoriesQuery,
  useGetActivityByIdQuery,
  useGetEquipmentTypesQuery,
  usePostNewActivityByProviderTeamMutation,
  useGetProviderTeamsByIdQuery,
} = activitiesApi;
