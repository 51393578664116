import { FC, PropsWithChildren, HTMLAttributes } from 'react';
import clsx from 'clsx';

type IFooterModal = PropsWithChildren<HTMLAttributes<HTMLElement>>;

const FooterModal: FC<IFooterModal> = ({ children, className, ...rest }) => {
  return (
    <footer
      className={clsx(
        'flex justify-end gap-2 sticky bottom-0 bg-bgColor-card p-4 border-t-2',
        className,
      )}
      {...rest}
    >
      {children}
    </footer>
  );
};

export { FooterModal };
