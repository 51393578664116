import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { TagType, AdminPageContextType } from 'src/shared/types';
import { Typography } from 'src/shared/ui/typography';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icons } from 'src/assets/icons';

import { DataGridContainer } from './components/DataGridContainer';

export interface TableManagerProps {
  backButtonDisabled?: boolean;
}

const TableManager: FC<TableManagerProps> = ({
  backButtonDisabled: isBackButtonDisabled = false,
}) => {
  const { modelName } = useParams<{ modelName: TagType }>();
  const { modelsOptions } = useOutletContext<AdminPageContextType>();
  const navigate = useNavigate();

  const handleNavigationClick = (href: string) => {
    navigate(href);
  };

  return (
    <div className="w-full flex flex-col gap-4 h-full">
      <div className="flex justify-between gap-4 flex-wrap">
        {!isBackButtonDisabled && (
          <IconButton
            size="lg"
            onClick={() => handleNavigationClick('/admin')}
          >
            <Icons.Filled.Edit.CloseIcon />
          </IconButton>
        )}

        {modelName && modelName in modelsOptions && (
          <Typography
            variant="h3"
            className="flex items-center leading-3"
          >
            {modelName}
          </Typography>
        )}
      </div>

      <div className="w-full h-full">
        {!modelName || !(modelName in modelsOptions) ? (
          <Typography
            variant="h1"
            className="text-center"
          >
            Table not found
          </Typography>
        ) : (
          <DataGridContainer modelName={modelName} />
        )}
      </div>
    </div>
  );
};

export { TableManager };
