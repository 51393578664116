import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetActivitiesByProviderTeamIdMutation } from 'src/store/api/activities';
import { dayjs } from 'src/shared/utils';
import { RootState } from 'src/store';

import { ActivityJobCard } from '../activityJobCard';
import { getFormattedActivities } from '../../helpers';

interface ActivityJobProcessProps {
  activityId: string;
  processIndex: number;
  activityCount?: number;
  setContainerWidth: (width?: number) => void;
}

const ActivityJobProcess: FC<ActivityJobProcessProps> = ({
  activityId,
  processIndex,
  activityCount,
  setContainerWidth,
}) => {
  const [timeZone, setTimeZone] = useState<string>(dayjs.tz.guess());

  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const dates = useMemo(
    () => datesState.map((date) => dayjs.utc(date).tz(timeZone)),
    [datesState, timeZone],
  );

  const startDate = dayjs(dates[0]).set('hour', 0).set('minute', 0).set('second', 0).toDate();
  const endDate = dayjs(dates[dates.length - 1])
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .toDate();

  const [getTeamActivities, { data: teamActivitiesData, isLoading: isLoadingTeamActivities }] =
    useGetActivitiesByProviderTeamIdMutation();

  useEffect(() => {
    if (teamActivitiesData && teamActivitiesData.activity[0]) {
      setTimeZone(teamActivitiesData.activity[0].OwnerLocation.LocationTimezone);
    }
  }, [teamActivitiesData]);

  const handleGetTeamActivities = async () => {
    if (isLoadingTeamActivities) {
      return;
    }

    try {
      await getTeamActivities({
        id: activityId,
        startDate: dayjs.utc(startDate).toISOString(),
        endDate: dayjs.utc(endDate).toISOString(),
        ...boardFilters,
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const cardsArray = useMemo(() => {
    return teamActivitiesData ? getFormattedActivities(teamActivitiesData.activity, timeZone) : [];
  }, [teamActivitiesData, timeZone]);

  const jobCardsWithSpread = useMemo(() => {
    return Array.from({ length: cardsArray.length }, () => false);
  }, [cardsArray]);

  const [cardsListWithSpread, setCardsListWithSpread] = useState(jobCardsWithSpread);

  useEffect(() => {
    setCardsListWithSpread(jobCardsWithSpread);
  }, [jobCardsWithSpread]);

  useEffect(() => {
    handleGetTeamActivities();
  }, [datesState, filterPeriod, activityId, boardFilters, activityCount]);

  return (
    <div className="flex items-center relative">
      {cardsArray.map((card, i) => {
        const previousDate =
          i > 0 ? cardsArray[i - 1].actualEnd || cardsArray[i - 1].endWork : startDate;

        const updateCardSpreadList = () => {
          setCardsListWithSpread((prev) => {
            return prev.map((item, index) => {
              if (index === i) {
                return !item;
              }

              return item;
            });
          });
        };

        return (
          <ActivityJobCard
            key={card.id}
            card={card}
            index={i}
            previousDate={previousDate}
            onEditJobCards={handleGetTeamActivities}
            updateCardSpreadList={updateCardSpreadList}
            hasPrevCardSpread={i > 0 && cardsListWithSpread[i - 1]}
            hasCardSpread={cardsListWithSpread[i]}
            processIndex={processIndex}
            timeZone={timeZone}
            datesStart={startDate}
            setContainerWidth={setContainerWidth}
          />
        );
      })}
    </div>
  );
};

export { ActivityJobProcess };
