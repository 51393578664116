import dayjs from 'dayjs';

import {
  ActivityHealth,
  ActivityTrack,
  ActivityUpdate,
  ActivityUpdated,
  WorkTrack,
} from 'src/shared/types';
import { Activity, ActivityState } from 'src/store/api/activities';

export const getDayHours = () => Array.from({ length: 24 }, (_, i) => `${i + 1}:00`);
export const getHoursWithoutMinutes = () => Array.from({ length: 24 }, (_, i) => `${i + 1}`);

export const getHeaderDayHours = (isDay?: boolean) => {
  return isDay
    ? [
        '0 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
      ]
    : ['00', '3', '6', '9', '12', '15', '18', '21'];
};

export const getWorkTrackValue = (workTrack: ActivityHealth | ActivityTrack | ActivityUpdated) => {
  const title = Object.keys(WorkTrack).find((key) => key === workTrack);
  if (title) {
    return WorkTrack[title as keyof typeof WorkTrack];
  }

  return 'Updated';
};

export const getMinWidth = (element: HTMLElement) => {
  const clone = element.cloneNode(true) as HTMLElement;

  clone.style.position = 'absolute';
  clone.style.visibility = 'hidden';
  clone.style.whiteSpace = 'nowrap';
  clone.style.width = 'auto';
  clone.style.minWidth = 'initial';
  clone.style.maxWidth = 'initial';

  document.body.appendChild(clone);

  const contentWidth = clone.scrollWidth;

  document.body.removeChild(clone);

  return contentWidth;
};

// TODO: remove after API integration
export const getTestParentValues = () => [
  'ID120 Hydroblast For Inspection',
  'ID121 Hydroblast For Inspection',
  'ID122 Hydroblast For Inspection',
  'ID123 Hydroblast For Inspection',
  'ID124 Hydroblast For Inspection',
  'ID125 Hydroblast For Inspection',
  'ID126 Hydroblast For Inspection',
  'ID127 Hydroblast For Inspection',
];

const formatActivityStates = (states: ActivityState[], timeZone: string): ActivityUpdate[] => {
  return states.map((state) => ({
    id: state.id,
    reportedBy: state.reportedBy,
    jobNumber: state.jobNumber,
    workTrack: state.health,
    workProgress: state.progress,
    workStatus: state.track as ActivityTrack,
    startWork: dayjs.utc(state.startDate).tz(timeZone).toDate(),
    endWork: dayjs.utc(state.endDate).tz(timeZone).toDate(),
    actualStart: dayjs.utc(state.startDate).tz(timeZone).toDate(),
    actualEnd: dayjs.utc(state.endDate).tz(timeZone).toDate(),
    activityName: state.name,
    providerName: state.provider.ProviderName,
    providerId: state.provider.ProviderID,
    providerTeam: state.providerTeam,
    providerTeamId: state.providerTeamId || state.providerTeam.id,
    notes: state.notes,
    createdAt: state.createdAt,
    equipment: state.equipment,
    equipmentTypeId: state.equipmentTypeId,
    delayCategoryID: state.delayCategoryID,
    delayCategoryName: state.delayCategory?.DelayCategory,
    percentComplete: state.progress,
  }));
};

export const getFormattedActivities = (activities: Activity[], timeZone: string) => {
  return activities.map(({ id, activityStates, actualState }) => {
    const sortedStates = [...activityStates].sort((a, b) => {
      return dayjs(a.createdAt).toDate().getTime() - dayjs(b.createdAt).toDate().getTime();
    });

    const firstState = sortedStates[0];

    return {
      id,
      reportedBy: actualState.reportedBy,
      jobNumber: actualState.jobNumber,
      workTrack: actualState.health,
      workProgress: actualState.progress,
      workStatus: actualState.track,
      startWork: dayjs.utc(firstState.startDate).tz(timeZone).toDate(),
      endWork: dayjs.utc(firstState.endDate).tz(timeZone).toDate(),
      actualStart: dayjs.utc(actualState.startDate).tz(timeZone).toDate(),
      actualEnd: dayjs.utc(actualState.endDate).tz(timeZone).toDate(),
      activityName: actualState.name,
      providerName: actualState.provider.ProviderName,
      providerId: actualState.provider.ProviderID,
      providerTeam: actualState.providerTeam,
      providerTeamId: actualState.providerTeamId || actualState.providerTeam.id,
      notes: actualState.notes,
      delayCategoryID: actualState.delayCategoryID,
      delayCategoryName: actualState.delayCategory?.DelayCategory,
      updates: formatActivityStates(sortedStates, timeZone),
      equipmentTypeId: actualState.equipmentTypeId || '',
      delayDuration: actualState.delayDuration,
    };
  });
};
