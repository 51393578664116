import { FC, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'src/shared/ui/card';
import { EquipmentList } from 'src/shared/ui/equipmentList';
import { IconButton } from 'src/shared/ui/iconButton';
import { Typography } from 'src/shared/ui/typography';
import { clsx, formatDate } from 'src/shared/utils';
import {
  useGetEquipmentQuery,
  useGetTicketsQuery,
  useGetMostUsedEquipmentQuery,
  useGetSidebarFiltersQuery,
  useGetProviderDivisionsQuery,
  useGetRentalEquipmentQuery,
  useGetMostUsedRentalEquipmentQuery,
} from 'src/store/api';
import { RootState, useAppDispatch } from 'src/store';
import { ReactComponent as FilterIcon } from 'src/assets/icons/outlined/controls/filter.svg';
import { modalConfigActions, filtersActions, selectConfig, configActions } from 'src/store/slices';
import { useGetProviderBranchesQuery } from 'src/store/api/providerBranches';
import { EquipmentEntity, EquipmentTab } from 'src/shared/types';
import { Filters } from 'src/shared/ui/filters';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { Button } from 'src/shared/ui/button';
import { Tag } from 'src/shared/ui/tag';
import { Tabs } from 'src/shared/ui/Tabs';

const filterMenuType = 'sidebar';

const availableEquipmentFilters = ['Provider Branch', 'Division', 'Equipment Type'];

type SidebarEquipmentCardProps = {
  searchQuery: string;
};

const SidebarEquipmentCard: FC<SidebarEquipmentCardProps> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();

  const config = useSelector(selectConfig);
  const selectedBranchFilters = useSelector(
    (state: RootState) => state.filters.sidebar.selectedFilters,
  ).filter((el) => el.id === 'Provider Branch');
  const filterAssignedForDate = useSelector(
    (state: RootState) => state.schedulerBoardSettings.filterAssignedForDate,
  );

  const { data: equipment = [] } = useGetEquipmentQuery({
    filters: config.sidebarFilters,
    pagination: {
      search: searchQuery,
    },
  });
  const { data: rentalEquipment = [] } = useGetRentalEquipmentQuery({
    pagination: {
      search: searchQuery,
    },
  });
  const { data: mostUsedEquipment = [] } = useGetMostUsedEquipmentQuery('');
  const { data: mostUsedRentalEquipment = [] } = useGetMostUsedRentalEquipmentQuery('');
  const { data: filters = [] } = useGetSidebarFiltersQuery(config.sidebarFilters);
  const { data = { tickets: [] } } = useGetTicketsQuery({});
  const { data: providerBranches = [] } = useGetProviderBranchesQuery('');
  const { data: providerDivisions = [] } = useGetProviderDivisionsQuery('');

  const [isOpen, setIsOpen] = useState(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedQuickFilters, setSelectedQuickFilters] = useState<string[]>(['All']);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const { tickets } = data;

  const appliedFiltersAmount = Object.values(config.sidebarFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const isOwnedTabActive = currentTabIndex === 0;

  const ticketsForToday = tickets.filter(
    (ticket) => formatDate(ticket.startDate) === formatDate(filterAssignedForDate),
  );

  const assignedEquipment = filterAssignedForDate
    ? ticketsForToday
        .flatMap((ticket) => ticket.equipment)
        .filter((equipment) => equipment.UnitNumber)
        .map((equipment) => equipment.UnitNumber)
    : [];

  const filterEquipmentItem = (equipment: EquipmentEntity) => {
    if (selectedQuickFilters.find((el) => el === 'All' || el === equipment?.Type)) {
      return true;
    }

    return false;
  };

  const visibleOwnedEquipment = equipment
    .filter(filterEquipmentItem)
    .sort((a, b) => (a?.UnitDescription?.length || 0) - (b?.UnitDescription?.length || 0));

  const visibleRentalEquipment = rentalEquipment
    .filter(filterEquipmentItem)
    .sort((a, b) => (a?.UnitDescription?.length || 0) - (b?.UnitDescription?.length || 0));

  const getFilteredOptions = (filter: { options: string[] }) => {
    const filteredOptions = filter.options.map((option) => ({
      label: option,
      value: option,
    }));

    return filteredOptions;
  };

  const handleTabChange = (tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
    dispatch(
      configActions.setOpenEquipmentTab(tabIndex === 0 ? EquipmentTab.owned : EquipmentTab.rental),
    );
  };

  const getFilteredDivisionOptions = () => {
    const selectedBranchOptionsIds = providerBranches.filter((option) =>
      selectedBranchFilters.some((selectedOption) => selectedOption.value === option.BranchName),
    );

    const visibleDivisions = providerDivisions.filter((option) =>
      selectedBranchOptionsIds.some(
        (selectedBranchOption) => selectedBranchOption.BranchID === option.ProviderBranchID,
      ),
    );

    const filteredOptions = visibleDivisions.map((option) => ({
      label: option.ProviderDivisionName,
      value: option.ProviderDivisionName,
    }));

    return filteredOptions;
  };

  const toggleQuickFilter = (value: string) => {
    if (value === 'All') {
      setSelectedQuickFilters(['All']);
      return;
    }

    const newQuickFilters = selectedQuickFilters.includes(value)
      ? selectedQuickFilters.filter((el) => el !== value && el !== 'All')
      : [...selectedQuickFilters.filter((el) => el !== 'All'), value];

    if (newQuickFilters.length === 0) {
      newQuickFilters.push('All');
    }

    setSelectedQuickFilters(newQuickFilters);
  };

  const openFilterMenu = (e: MouseEvent<HTMLButtonElement>) => {
    if (isOpen) {
      e.stopPropagation();
    }

    setIsFilterMenuOpen((isOpen) => !isOpen);

    dispatch(modalConfigActions.setOpenAdditionalEquipmentModalTicketId(''));
  };

  const getFilterButton = (label: string, name: string) => {
    return (
      <Button
        size="sm"
        variant={selectedQuickFilters.includes(name) ? 'filled' : 'outlined'}
        color={selectedQuickFilters.includes(name) ? 'primary' : 'basic'}
        className={clsx(
          '!rounded-[25px] w-[103px] !h-[27px]',
          selectedQuickFilters.includes(name) && 'border border-solid border-transparent',
        )}
        onClick={() => toggleQuickFilter(name)}
      >
        {label}
      </Button>
    );
  };

  const getFilters = () => {
    const availableFilters = filters.filter((filter) =>
      availableEquipmentFilters.includes(filter.label),
    );

    return availableFilters.map((filter, index) => (
      <FilterSelect
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        options={
          filter.label === 'Division' ? getFilteredDivisionOptions() : getFilteredOptions(filter)
        }
        label={filter.label}
        placeholder={`Filter by ${filter.label}`}
        id={`${filter.label} Sidebar`}
        type="sidebar"
        disabled={filter.label === 'Division' && !selectedBranchFilters.length}
        isSingle={filter.label === 'Provider Branch'}
        onRemoveAllClick={
          filter.label === 'Provider Branch'
            ? () => {
                dispatch(
                  filtersActions.removeFiltersById({
                    type: 'sidebar',
                    id: 'Division',
                  }),
                );
              }
            : undefined
        }
        onOptionSelect={
          filter.label === 'Provider Branch'
            ? () => {
                dispatch(
                  filtersActions.removeFiltersById({
                    type: 'sidebar',
                    id: 'Division',
                  }),
                );
              }
            : undefined
        }
      />
    ));
  };

  const cardTitle = (
    <div className="flex w-full justify-between items-center">
      <div className="flex justify-between items-center gap-2">
        <Typography variant="h3">Equipment</Typography>

        <Tag type="light">{equipment.length + rentalEquipment.length}</Tag>
      </div>

      <div className="relative">
        <IconButton
          size="sm"
          onClick={openFilterMenu}
          className={clsx(
            'w-[24px] h-[24px]',
            isFilterMenuOpen && '!bg-[rgba(18,60,142,0.08)] rounded-full',
          )}
          iconClassName={clsx(isFilterMenuOpen && 'fill-brandingColor-primary-gradient')}
        >
          <FilterIcon />
        </IconButton>

        {!!appliedFiltersAmount && (
          <Tag
            type="white"
            className="absolute right-[-2px] top-[-9px] !px-1 text-white bg-brandingColor-primary-gradient"
          >
            {appliedFiltersAmount}
          </Tag>
        )}
      </div>
    </div>
  );

  const tabs = [
    {
      title: 'Owned',
      component: <>Owned</>,
      amount: visibleOwnedEquipment.length,
    },
    {
      title: 'Rental',
      component: <>Rental</>,
      amount: visibleRentalEquipment.length,
    },
  ];

  return (
    <Card
      handleAccordionChange={(isOpen) => setIsOpen(!isOpen)}
      open={isOpen}
      isAccordion
      iconSize="none"
      title={cardTitle}
      className="relative gap-y-5 min-h-[60px]"
    >
      <>
        <Tabs
          items={tabs}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={handleTabChange}
        />

        <hr />

        <div className="flex flex-wrap gap-2 mb-6 pt-4">
          {getFilterButton('All equipment', 'All')}

          {(isOwnedTabActive ? mostUsedEquipment : mostUsedRentalEquipment).map((el) => (
            <Button
              key={el.Type}
              size="sm"
              className="!rounded-[25px] min-w-[95px] !h-[27px]"
              variant={selectedQuickFilters.includes(el.Type) ? 'filled' : 'outlined'}
              color={selectedQuickFilters.includes(el.Type) ? 'primary' : 'basic'}
              onClick={() => toggleQuickFilter(el.Type)}
            >
              {el.Type}
            </Button>
          ))}
        </div>

        {isFilterMenuOpen && (
          <Filters
            title="Filters"
            closeMenu={setIsFilterMenuOpen}
            type={filterMenuType}
            className="top-[47px] left-[15px] min-w-[347px] max-w-[347px]"
            isOpen={isFilterMenuOpen}
          >
            {getFilters()}
          </Filters>
        )}

        <EquipmentList
          isDropDisabled
          assignedEquipment={assignedEquipment}
          equipment={isOwnedTabActive ? visibleOwnedEquipment : visibleRentalEquipment}
        />
      </>
    </Card>
  );
};

export type { SidebarEquipmentCardProps };
export { SidebarEquipmentCard };
