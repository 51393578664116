import { JobFormInitialValues } from './types';

const jobFormInitialValues: JobFormInitialValues = {
  dateRange: {
    startDate: '',
    endDate: '',
  },
  owner: '',
  ownerContract: '',
  ownerContact: '',
  ownerRepresentative: '',
  ownerLocation: '',
  po: '',
  wo: '',
  department: '',
  serviceLine: '',
  serviceType: '',
  description: '',
  jobCategory: 'Routine',
  jobIdentifier: '',
  isLunchAllowed: 'Yes',
  shiftType: 'Day',
  JELL: '',
  isJELEnforced: false,
  defaultScheduledStartTime: '',
  defaultScheduledDuration: '',
  defaultScheduledTimeOnYard: '',
  defaultScheduledBaseTime: '',
  sro: '',
};

const isLunchAllowedOptions = ['Yes', 'No'];
const shiftTypes = ['Day', 'Night'];

export { jobFormInitialValues, isLunchAllowedOptions, shiftTypes };
