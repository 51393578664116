import clsx from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { BoardLayout, TimePeriod } from 'src/shared/types';
import { Button } from 'src/shared/ui/button';
import {
  getLocalMonthDays,
  getRangeOfDatesBasedOnLayout,
  getWeekDaysFromMonday,
} from 'src/shared/utils';
import { RootState, useAppDispatch } from 'src/store';
import { calendarActions } from 'src/store/slices';

const periods = [
  {
    title: 'Day',
    value: TimePeriod.Day,
  },
  {
    title: 'Week',
    value: TimePeriod.Week,
  },
  {
    title: 'Month',
    value: TimePeriod.Month,
  },
];

const ChoosingPeriodButtons: FC = () => {
  const dispatch = useAppDispatch();
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const datesState = useSelector((state: RootState) => state.calendar.dates);

  const handleChangePeriod = (period: BoardLayout) => {
    if (period === TimePeriod.Day) {
      dispatch(
        calendarActions.changeLayout({
          layout: TimePeriod.Day,
        }),
      );
      dispatch(
        calendarActions.changeDates({
          dates: getRangeOfDatesBasedOnLayout(datesState[0])(period),
        }),
      );
      dispatch(
        calendarActions.changeSelectedDate({
          date: datesState[0],
        }),
      );
    }
    if (period === TimePeriod.Month) {
      const monthDays = getLocalMonthDays(datesState[0]);

      dispatch(
        calendarActions.changeLayout({
          layout: TimePeriod.Month,
        }),
      );
      dispatch(
        calendarActions.changeDates({
          dates: monthDays,
        }),
      );
      dispatch(
        calendarActions.changeSelectedDate({
          date: monthDays[0],
        }),
      );
    }
    if (period === TimePeriod.Week) {
      const weekDays = getWeekDaysFromMonday(datesState[0]);

      dispatch(
        calendarActions.changeLayout({
          layout: TimePeriod.Week,
        }),
      );
      dispatch(
        calendarActions.changeDates({
          dates: weekDays,
        }),
      );
      dispatch(
        calendarActions.changeSelectedDate({
          date: weekDays[0],
        }),
      );
    }
  };

  return (
    <div className="flex gap-3 items-center justify-center">
      {periods.map((period) => {
        const isActiveButton = filterPeriod === period.value;

        return (
          <Button
            key={period.value}
            variant={isActiveButton ? 'filled' : 'outlined'}
            onClick={() => handleChangePeriod(period.value)}
            className={clsx(
              isActiveButton
                ? 'bg-brandingColor-primary-gradient text-white'
                : '!border-brandingColor-primary-gradient !text-brandingColor-primary-gradient',
            )}
          >
            {period.title}
          </Button>
        );
      })}
    </div>
  );
};

export { ChoosingPeriodButtons };
