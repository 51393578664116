export enum WorkTrack {
  OnTrack = 'On-track',
  Delayed = 'Delayed',
  SetStatus = 'Set Status',
  Scheduled = 'Scheduled',
  OnHold = 'On-hold',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Updated = 'Updated',
}
export type ActivityHealth = 'OnTrack' | 'Delayed' | 'OnHold';
export type ActivityTrack = 'SetStatus' | 'Scheduled' | 'InProgress' | 'Completed';
export type ActivityUpdated = 'Updated';

export interface ActivityUpdate {
  id: string;
  jobNumber: string;
  reportedBy: string;
  workStatus: ActivityTrack;
  workProgress?: number;
  workTrack: ActivityHealth;
  startWork?: Date;
  endWork?: Date;
  actualStart?: Date;
  actualEnd?: Date;
  delayCategoryID?: string;
  delayCategoryName?: string;
  activityName?: string;
  providerId: string;
  providerName: string;
  providerTeam: {
    id: string;
    name: string;
  };
  providerTeamId: string;
  notes?: string;
  percentComplete?: number;
  createdAt: string;
  equipment?: string;
  type?: string;
}

export interface ActivityJob {
  id: string;
  jobNumber: string;
  reportedBy: string;
  workStatus: ActivityTrack;
  workProgress: number;
  workTrack: ActivityHealth;
  startWork: Date;
  endWork: Date;
  actualStart?: Date;
  actualEnd?: Date;
  delayCategoryID?: string;
  delayCategoryName?: string;
  activityName: string;
  providerId: string;
  providerName: string;
  providerTeam: {
    id: string;
    name: string;
  };
  providerTeamId: string;
  updates: null | ActivityUpdate[];
  notes?: string;
  percentComplete?: number;
  equipment?: string;
  equipmentTypeId: string;
  delayDuration?: string;
}

export type UpdateActivityParams = {
  id: string;
  actualStart?: Date;
  actualEnd?: Date;
  notes?: string;
  delay?: string;
  percentComplete: number;
};

export type SetEditActivityParams = {
  id: string;
  startWork: Date;
  endWork: Date;
  crew: string;
  workStatus: WorkTrack;
};

export enum TimePeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export type ActivityFilters = {
  jobID?: string[];
  provider?: string[];
  crew?: string[];
  status?: string[];
  health?: string[];
};
