import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/store';
import { schedulerBoardSettingsActions } from 'src/store/slices/schedulerBoardSettings';

const useScrollToToday = <T extends HTMLDivElement>() => {
  const ref = useRef<T>(null);
  const dispatch = useAppDispatch();
  const shouldScrollToToday = useSelector(
    (state: RootState) => state.schedulerBoardSettings.shouldScrollToToday,
  );

  useEffect(() => {
    if (shouldScrollToToday && ref.current)
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

    return () => {
      dispatch(schedulerBoardSettingsActions.changeShouldScrollToToday(false));
    };
  }, [ref, shouldScrollToToday, dispatch]);

  return { ref };
};

export { useScrollToToday };
