/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
import { FC, PropsWithChildren, useState } from 'react';
import { useFormik } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useSelector } from 'react-redux';

import { Button } from 'src/shared/ui/button';
import { Datepicker } from 'src/shared/ui/datepicker';
import { IconButton } from 'src/shared/ui/iconButton';
import { Modal } from 'src/shared/ui/modal';
import { TextField } from 'src/shared/ui/textField';
import { Typography } from 'src/shared/ui/typography';
import { ReactComponent as CloseCircleIcon } from 'src/assets/icons/filled/edit/close-circle-1.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/outlined/misc/clock.svg';
import { ReactComponent as ChevronRightIcon } from 'src/assets/icons/outlined/chevrons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from 'src/assets/icons/outlined/chevrons/chevron-left.svg';
import { ReactComponent as CheckmarkIcon } from 'src/assets/icons/filled/edit/checkmark.svg';
import { clsx } from 'src/shared/utils/clsx';
import { SelectInput } from 'src/shared/ui/selectInput';
import { SelectInputItem } from 'src/shared/ui/selectInput/ui/selectInputItem';
import {
  dayjs,
  getEndTimeOptions,
  getFormattedTime,
  getFormattedTimePickerDateTime,
  getFormattedTimePickerTime,
  getPaginationDays,
  getRandomNumber,
  getTimePickerOptions,
  showToastErrorMessage,
} from 'src/shared/utils';
import {
  useGetJobsQuery,
  useAddTicketMutation,
  useUpdateTicketMutation,
  useGetOwnersQuery,
  useGetTicketJELLQuery,
  useGetJobCategoriesQuery,
} from 'src/store/api';
import { JobEntity, TicketEntity, TicketStatus } from 'src/shared/types';
import { RootState, useAppDispatch } from 'src/store';
import { configActions, selectConfig } from 'src/store/slices';
import { IS_AM_PM_FORMAT } from 'src/config';

import { TimePickerInput } from '../../timePickerInput';
import { EndsTomorrowIndicator } from '../../endsTomorrowIndicator';
import { CheckboxItem, Checkboxes } from '../../checkboxes/checkboxes';

import { Step, TicketFormInitialValues } from './types';
import { isLunchAllowedOptions, plannedLunchDurationOptions, shiftTypes } from './constants';

type CreateOrUpdateTicketModalProps<T = 'create' | 'update'> = T extends 'create'
  ? PropsWithChildren & {
      isOpen: boolean;
      type: 'create';
      setIsOpen: (isOpen: boolean) => void;
      job?: JobEntity;
      ticket?: TicketEntity;
      date?: string;
    }
  : {
      isOpen: boolean;
      type: 'update';
      setIsOpen: (isOpen: boolean) => void;
      job: JobEntity;
      ticket: TicketEntity;
      date: string;
    };

const CreateOrUpdateTicketModal: FC<CreateOrUpdateTicketModalProps> = ({
  isOpen = false,
  setIsOpen,
  job,
  ticket,
  type,
  date,
}) => {
  const dispatch = useAppDispatch();

  const dates = useSelector((state: RootState) => state.calendar.dates);
  const config = useSelector(selectConfig);

  const [startDate, endDate] = getPaginationDays(dates);

  const {
    data = {
      jobs: [],
    },
  } = useGetJobsQuery({
    filters: {
      ...config.boardFilters,
      startDate,
      endDate,
    },
  });
  const [addTicket] = useAddTicketMutation();
  const [updateTicket] = useUpdateTicketMutation();
  const [selectedStep, setSelectedStep] = useState<Step>(Step.details);
  const { data: owners = [] } = useGetOwnersQuery('');
  const { data: TicketJELItems = [] } = useGetTicketJELLQuery('');
  const { data: jobCategories = [] } = useGetJobCategoriesQuery('');

  const timezone = job?.ownerLocation?.LocationTimezone;

  const { jobs } = data;

  const isOwnerStepOpen = selectedStep === Step.owner;
  const isDetailsStepOpen = selectedStep === Step.details;
  const isScheduleStepOpen = selectedStep === Step.schedule;

  const initialValues: TicketFormInitialValues = {
    job: job?.workRequestNotes || '',
    dateRange: {
      startDate: null,
      endDate: null,
    },
    ticketCategory: ticket?.jobCategory?.Category || job?.jobCategory?.Category || 'Routine',
    ownerContract: job?.ownerContract?.RateSheetDescription || '',
    po: ticket?.po || job?.po || '',
    wo: ticket?.wo || job?.wo || '',
    scopeOfWork: ticket?.projectName || '',
    shiftType: ticket?.shift || job?.shiftType || 'Day',
    isLunchAllowed: ticket?.lunchAllowed || job?.isLunchAllowed || 'Yes',
    plannedLunchDuration: ticket?.scheduledLunch || '30 Minutes',
    owner: job?.owner?.OwnerName || '',
    ownerLocation: job?.ownerLocation?.OwnerLocation || '',
    ownerContact:
      (ticket?.ownerContact &&
        `${ticket?.ownerContact?.FirstName} ${ticket?.ownerContact?.LastName}`) ||
      (job?.ownerContact && `${job?.ownerContact?.FirstName} ${job?.ownerContact?.LastName}`) ||
      '',
    ownerRepresentative:
      (ticket?.ownerRepresentative &&
        `${ticket?.ownerRepresentative?.FirstName} ${ticket?.ownerRepresentative?.LastName}`) ||
      (job?.ownerRepresentative &&
        `${job?.ownerRepresentative?.FirstName} ${job?.ownerRepresentative?.LastName}`) ||
      '',
    startTime:
      (ticket?.startTime
        ? getFormattedTimePickerDateTime(ticket.startTime)
        : getFormattedTimePickerDateTime(job?.defaultScheduledStartTime)) || '',
    duration:
      (ticket?.duration
        ? getFormattedTimePickerTime(ticket.duration, true)
        : getFormattedTimePickerTime(job?.defaultScheduledDuration, true)) || '',
    thisShiftTimeOnYard:
      (ticket?.thisShiftTimeOnYard
        ? getFormattedTimePickerDateTime(ticket.thisShiftTimeOnYard)
        : getFormattedTimePickerDateTime(job?.defaultScheduledTimeOnYard)) || '',
    thisShiftBaseTime:
      (ticket?.thisShiftBaseTime
        ? getFormattedTimePickerDateTime(ticket.thisShiftBaseTime)
        : getFormattedTimePickerDateTime(job?.defaultScheduledBaseTime)) || '',
    JELL: ticket?.JELL?.AdditionalKitDescription1 || job?.JELL?.AdditionalKitDescription1 || '',
  };

  const toggleModal = (isOpen: boolean) => {
    setIsOpen(!isOpen);
    resetForm();
    setSelectedStep(Step.details);
  };

  const onSubmit = async (values: TicketFormInitialValues) => {
    const selectedJob = jobs.find((el) => el.id === job?.id);
    toggleModal(isOpen);

    const {
      dateRange,
      scopeOfWork,
      shiftType,
      po,
      wo,
      plannedLunchDuration,
      isLunchAllowed,
      ticketCategory,
    } = values;

    if (!selectedJob) {
      return;
    }

    const ownerContact = owners
      .find((owner) => owner.OwnerName === values.owner)
      ?.OwnerPersonnel.find(
        (ownerPersonnel) =>
          `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}` === values.ownerContact,
      );
    const ownerRepresentative = owners
      .find((owner) => owner.OwnerName === values.owner)
      ?.OwnerPersonnel.find(
        (ownerPersonnel) =>
          `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}` === values.ownerRepresentative,
      );

    const category = jobCategories.find((category) => category.Category === ticketCategory);

    const JELL = job?.isJELLEnforced
      ? job?.JELL
      : TicketJELItems.find((jell) => jell.AdditionalKitDescription1 === values.JELL);

    const ticketId = getRandomNumber();

    const [hoursStart, minutesStart] =
      IS_AM_PM_FORMAT === 'true'
        ? startTime.split(' ')[0].split(':').map(Number)
        : startTime.split(':').map(Number);
    const [hoursYard, minutesYard] =
      IS_AM_PM_FORMAT === 'true'
        ? thisShiftTimeOnYard.split(' ')[0].split(':').map(Number)
        : thisShiftTimeOnYard.split(':').map(Number);
    const [hoursBase, minutesBase] =
      IS_AM_PM_FORMAT === 'true'
        ? thisShiftBaseTime.split(' ')[0].split(':').map(Number)
        : thisShiftBaseTime.split(':').map(Number);

    const [hoursDuration, minutesDuration] = duration.split(':').map(Number);

    const hoursEnd = hoursStart + hoursDuration;
    const minutesEnd = minutesStart + minutesDuration;

    const start = dayjs
      .tz(dateRange?.startDate, timezone)
      .hour(hoursStart)
      .minute(minutesStart)
      .second(0);
    const end = dayjs.tz(dateRange?.endDate, timezone).hour(hoursEnd).minute(minutesEnd).second(0);
    const yard = thisShiftTimeOnYard
      ? dayjs.tz(dateRange?.startDate, timezone).hour(hoursYard).minute(minutesYard).second(0)
      : '';
    const base = thisShiftBaseTime
      ? dayjs.tz(dateRange?.startDate, timezone).hour(hoursBase).minute(minutesBase).second(0)
      : '';

    const startDate = start.format();
    const endDate = end.format();
    const yardDate = yard ? yard.format() : '';
    const baseDate = base ? base.format() : '';

    const minutesDifference = end.diff(start, 'minute');
    const hours = Math.floor(minutesDifference / 60);
    const minutes = minutesDifference % 60;

    const ticketDuration = dayjs('1970-01-01T00:00:00')
      .utc()
      .hour(+hours)
      .minute(+minutes)
      .format();

    const ticketOptions: Partial<TicketEntity> = {
      job: selectedJob.id,
      projectName: scopeOfWork,
      jobCategory: category,
      owner: selectedJob.owner,
      ownerLocation: selectedJob.ownerLocation,
      ownerContract: selectedJob.ownerContract,
      po,
      wo,
      ownerRepresentative,
      ownerContact,
      startDate,
      endDate,
      shift: shiftType,
      lunchAllowed: isLunchAllowed,
      scheduledLunch: plannedLunchDuration,
      startTime: startDate,
      endTime: endDate,
      thisShiftTimeOnYard: yardDate,
      thisShiftBaseTime: baseDate,
      status: ticket?.status || TicketStatus.ReadyToDispatch,
      JELL,
      duration: ticketDuration,
    };

    if (type === 'create') {
      try {
        dispatch(configActions.addLoadingEntityId(ticketId));

        dispatch(
          configActions.changeRowHeight({
            selectedWeek: config.selectedWeek,
            row: selectedJob?.row || 0,
            height: 0,
          }),
        );

        await addTicket({
          ...ticketOptions,
          id: ticketId,
          equipment: [],
          employees: [],
        } as unknown as TicketEntity).unwrap();
      } catch {
        showToastErrorMessage(
          `Sorry, an error occurred, when you tried to create a ticket, please check your internet connection`,
        );
      } finally {
        dispatch(configActions.removeLoadingEntityId(ticketId));
      }
    } else {
      try {
        await updateTicket({
          ...ticketOptions,
          id: ticket.id,
          employees: ticket.employees,
          equipment: ticket.equipment,
          crewLeader: ticket.crewLeader,
        } as TicketEntity).unwrap();
      } catch {
        showToastErrorMessage(
          `Sorry, an error occurred, when you tried to update a ticket, please check your internet connection`,
        );
      }
    }
  };

  const Schema = z.object({
    job: z.string().nonempty('Work job is required'),
    projectName: z.string().nonempty('Ticket description is required'),
    dateRange: z.object({
      startDate: z.custom(
        (value) => {
          return dayjs(value as string)
            .utc()
            .isBetween(
              dayjs(job?.requestedStartDate),
              dayjs(job?.requestedCompleteDate),
              'day',
              '[]',
            );
        },
        () => {
          return {
            message: `The date range entered exceeds the dates of the selected work job (${dayjs(
              job?.requestedStartDate,
            )
              .utc()
              .format('MMM D')} - ${dayjs(job?.requestedCompleteDate)
              .utc()
              .format('MMM D')}). Please edit the work job dates or adjust the date of the ticket.`,
          };
        },
      ),
    }),
  });

  const formattedDate = String(dayjs(date).hour(12).minute(0).second(0));

  const { values, handleChange, handleBlur, handleSubmit, errors, resetForm, setFieldValue } =
    useFormik({
      onSubmit,
      validationSchema: toFormikValidationSchema(Schema),
      initialValues:
        type === 'create'
          ? {
              ...initialValues,
              dateRange: {
                startDate: formattedDate || null,
                endDate: formattedDate || null,
              },
            }
          : {
              ...initialValues,
              dateRange: {
                startDate: formattedDate || null,
                endDate: formattedDate || null,
              },
            },
      enableReinitialize: true,
    });

  const startTime = getFormattedTime(values.startTime);
  const duration = getFormattedTime(values.duration, getTimePickerOptions(true));
  const thisShiftTimeOnYard = getFormattedTime(values.thisShiftTimeOnYard);
  const thisShiftBaseTime = getFormattedTime(values.thisShiftBaseTime);

  const { endTime, endTimeEndsTomorrow } = getEndTimeOptions(startTime, duration);

  const isOwnerStepDisabled = Boolean(
    values.ticketCategory === '' || values.job === '' || values.scopeOfWork === '',
  );

  const isScheduleStepDisabled = Boolean(
    values.owner === '' ||
      values.ownerContract === '' ||
      values.ownerLocation === '' ||
      isOwnerStepDisabled,
  );

  const isSaveDisabled = Boolean(
    !values.dateRange?.startDate ||
      !values.dateRange?.endDate ||
      values.shiftType === '' ||
      values.isLunchAllowed === '' ||
      values.plannedLunchDuration === '' ||
      values.startTime === '' ||
      values.duration === '' ||
      errors.dateRange?.startDate ||
      errors.dateRange?.endDate ||
      isScheduleStepDisabled,
  );

  const isBackButtonVisible = isOwnerStepOpen || isScheduleStepOpen;

  const getIsPrimaryButtonDisabled = () => {
    if (isDetailsStepOpen) {
      return isOwnerStepDisabled;
    }

    if (isOwnerStepOpen) {
      return isScheduleStepDisabled;
    }

    return isSaveDisabled;
  };

  const changeStep = (page: string) => {
    if (selectedStep === Step.details && !isOwnerStepDisabled && page === Step.owner) {
      setSelectedStep(page);
    }

    if (selectedStep === Step.owner && !isScheduleStepDisabled && page === Step.schedule) {
      setSelectedStep(page);
    }

    if (selectedStep === Step.owner && page === Step.details) {
      setSelectedStep(page);
    }

    if (selectedStep === Step.schedule && (page === Step.owner || page === Step.details)) {
      setSelectedStep(page);
    }

    if (selectedStep === Step.details && !isScheduleStepDisabled && page === Step.schedule) {
      setSelectedStep(page);
    }
  };

  const currentStep = (
    <div className="flex justify-between gap-6">
      {Object.values(Step).map((page, index) => (
        <button
          type="button"
          key={page}
          className="flex flex-col w-full text-left gap-3"
          onClick={() => changeStep(page)}
        >
          <Typography
            className={clsx(
              selectedStep === page ? 'text-brandingColor-primary-gradient' : 'text-textColor-hint',
            )}
            variant="h3"
          >
            {`${index + 1}. ${page}`}
          </Typography>

          <div
            className={clsx(
              'w-full h-1 rounded-[123px]',
              selectedStep === page ? 'bg-brandingColor-primary-gradient' : 'bg-textColor-hint',
            )}
          />
        </button>
      ))}
    </div>
  );

  const detailsStepContent = (
    <>
      <SelectInput
        isRequired
        name="ticketCategory"
        value={values.ticketCategory}
        label="Ticket Category"
        placeholder="Select Ticket Category"
        onClear={(name) => setFieldValue(name, '')}
        items={jobCategories.map((option) => ({
          label: (
            <SelectInputItem selected={values.ticketCategory === option.Category}>
              {option.Category}
            </SelectInputItem>
          ),
          value: option.Category,
          onClick: () => {
            setFieldValue('ticketCategory', option.Category);
          },
        }))}
      />

      <TextField
        name="job"
        value={values.job}
        onChange={handleChange}
        label="Job"
        placeholder="Job"
        isRequired
        disabled
      />

      <TextField
        name="scopeOfWork"
        value={values.scopeOfWork}
        onChange={handleChange}
        label="Scope Of Work"
        placeholder="Scope of work"
        isRequired
      />

      <SelectInput
        name="JELL"
        value={values.JELL}
        disabled={job?.isJELLEnforced || type === 'update'}
        label="JELL"
        placeholder="Select JELL"
        onClear={(name) => setFieldValue(name, '')}
        items={
          TicketJELItems.filter((jell) => jell.Contract === job?.ownerContract?.RateSheetID).map(
            (jell) => ({
              label: (
                <SelectInputItem selected={values.JELL === jell.AdditionalKitDescription1}>
                  {jell.AdditionalKitDescription1}
                </SelectInputItem>
              ),
              value: jell.AdditionalKitDescription1,
              onClick: () => {
                handleChange({
                  target: {
                    name: 'JELL',
                    value: jell.AdditionalKitDescription1,
                  },
                });
              },
              customKey: jell.EquipmentKitID,
            }),
          ) ?? []
        }
      />

      {job?.isJELLEnforced && (
        <Typography variant="p1">
          You cannot change the jell because it was not allowed in the Job
        </Typography>
      )}
    </>
  );

  const ownerStepContent = (
    <>
      <div className="flex flex-col justify-between gap-4 min-w-[868px]">
        <TextField
          name="owner"
          value={values.owner}
          onChange={handleChange}
          label="Owner"
          placeholder="Owner"
          isRequired
          disabled
        />

        <TextField
          name="ownerLocation"
          value={values.ownerLocation}
          onChange={handleChange}
          label="Location"
          placeholder="Owner Location"
          isRequired
          disabled
        />

        <TextField
          name="ownerContract"
          value={values.ownerContract}
          onChange={handleChange}
          label="Rate Sheet"
          placeholder="Owner Rate Sheet"
          isRequired
          disabled
        />

        <div className="flex justify-between gap-5">
          <SelectInput
            name="ownerContact"
            value={values.ownerContact}
            label="Contact"
            placeholder="Select Owner Contact"
            onClear={(name) => setFieldValue(name, '')}
            items={
              owners
                ?.find((owner) => owner?.OwnerName === values?.owner)
                ?.OwnerPersonnel.map((ownerPersonnel) => ({
                  label: (
                    <SelectInputItem
                      selected={
                        values.ownerContact ===
                        `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`
                      }
                    >
                      {`${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`}
                    </SelectInputItem>
                  ),
                  value: `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                  onClick: () => {
                    handleChange({
                      target: {
                        name: 'ownerContact',
                        value: `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                      },
                    });
                  },
                })) ?? []
            }
          />

          <SelectInput
            name="ownerRepresentative"
            value={values.ownerRepresentative}
            label="Representative"
            placeholder="Select Owner Representative"
            onClear={(name) => setFieldValue(name, '')}
            items={
              owners
                ?.find((owner) => owner?.OwnerName === values?.owner)
                ?.OwnerPersonnel.map((ownerPersonnel) => ({
                  label: (
                    <SelectInputItem
                      selected={
                        values.ownerRepresentative ===
                        `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`
                      }
                    >
                      {`${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`}
                    </SelectInputItem>
                  ),
                  value: `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                  onClick: () => {
                    handleChange({
                      target: {
                        name: 'ownerRepresentative',
                        value: `${ownerPersonnel.FirstName} ${ownerPersonnel.LastName}`,
                      },
                    });
                  },
                })) ?? []
            }
          />
        </div>
      </div>

      <div className="flex gap-4">
        <TextField
          value={values.po}
          name="po"
          onChange={handleChange}
          onBlur={handleBlur}
          label="P.O"
          placeholder="#12345"
          error={errors.po}
        />

        <TextField
          value={values.wo}
          name="wo"
          onChange={handleChange}
          onBlur={handleBlur}
          label="W.O"
          placeholder="#12345"
          error={errors.wo}
        />
      </div>
    </>
  );

  const scheduleStepContent = (
    <div className="flex flex-col gap-y-[32px]">
      <div className="flex flex-col gap-4 pt-[26px] pb-4 px-3 border border-solid border-textColor-light rounded-lg">
        <Typography
          variant="p1"
          fontWeight="bold"
        >
          Due Dates&nbsp;
          <span className="text-textColor-danger">*</span>
        </Typography>

        <div className="flex flex-col gap-y-2">
          <Typography variant="label">Date of Ticket</Typography>

          <Datepicker
            error={errors.dateRange?.startDate || errors.dateRange?.endDate}
            useRange={false}
            placeholder="Select your date"
            timezone={timezone}
            value={values.dateRange}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'dateRange',
                  value,
                },
              })
            }
            asSingle
            displayFormat="dddd, DD MMMM"
            separator="-"
            toggleClassName={clsx(
              values.dateRange?.startDate && values.dateRange?.endDate
                ? 'absolute right-[12px] top-[12px] bg-transparent'
                : 'absolute left-[16px] top-[12px] bg-transparent',
            )}
            iconPosition="start"
            toggleIcon={(open) => {
              return (
                <IconButton
                  iconClassName="fill-textColor-tertiary"
                  size="none"
                >
                  {open ? <ClockIcon /> : <CloseCircleIcon />}
                </IconButton>
              );
            }}
            inputClassName="w-full"
          />
        </div>
      </div>

      <div className="flex justify-between items-center gap-4">
        <TimePickerInput
          label="Start Time"
          name="startTime"
          value={startTime}
          handleSelect={(name, value) => setFieldValue(name, value)}
          onClear={(name) => setFieldValue(name, '')}
          isRequired
        />

        <TimePickerInput
          label="Shift Duration"
          name="duration"
          value={duration}
          handleSelect={(name, value) => setFieldValue(name, value)}
          onClear={(name) => setFieldValue(name, '')}
          isRequired
        />

        <TextField
          value={endTime}
          name="endTime"
          label="End Time"
          placeholder="hh:mm"
          className="relative w-[110px]"
          customIndicator={
            endTimeEndsTomorrow ? (
              <EndsTomorrowIndicator
                className="absolute right-0"
                isTooltipAvailable={endTimeEndsTomorrow}
              />
            ) : undefined
          }
          disabled
        />
      </div>

      {/* <div className="flex justify-between gap-4">
        <TimePickerInput
          label="Yard Time"
          name="thisShiftTimeOnYard"
          value={thisShiftTimeOnYard}
          handleSelect={(name, value) => setFieldValue(name, value)}
          onClear={(name) => setFieldValue(name, '')}
        />

        <TimePickerInput
          label="Base Time"
          name="thisShiftBaseTime"
          value={thisShiftBaseTime}
          handleSelect={(name, value) => setFieldValue(name, value)}
          onClear={(name) => setFieldValue(name, '')}
        />
      </div> */}

      <Checkboxes
        label="Shift Type"
        options={shiftTypes.reduce<CheckboxItem[]>((acc, shiftType) => {
          return [
            ...acc,
            {
              label: shiftType,
              onClick: () =>
                handleChange({
                  target: {
                    name: 'shiftType',
                    value: shiftType,
                  },
                }),
            },
          ];
        }, [])}
        selectedValue={values.shiftType}
        isRequired
      />

      <div className="flex gap-[32px]">
        <Checkboxes
          label="Lunch Billable"
          options={isLunchAllowedOptions.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                label: option,
                onClick: () =>
                  handleChange({
                    target: {
                      name: 'isLunchAllowed',
                      value: option,
                    },
                  }),
              },
            ];
          }, [])}
          selectedValue={values.isLunchAllowed}
          isRequired
        />

        <Checkboxes
          label="Planned Lunch Duration"
          options={plannedLunchDurationOptions.reduce<CheckboxItem[]>((acc, option) => {
            return [
              ...acc,
              {
                className: 'min-w-[82px]',
                label: option,
                onClick: () =>
                  handleChange({
                    target: {
                      name: 'plannedLunchDuration',
                      value: option,
                    },
                  }),
              },
            ];
          }, [])}
          selectedValue={values.plannedLunchDuration}
          isRequired
        />
      </div>
    </div>
  );

  const moveToPreviousStep = () => {
    setSelectedStep(selectedStep === Step.schedule ? Step.owner : Step.details);
  };

  const moveToNextStep = () => {
    setSelectedStep(selectedStep === Step.details ? Step.owner : Step.schedule);
  };

  const handlePrimaryButtonClick = () => {
    if (isScheduleStepOpen) {
      onSubmit(values);

      return;
    }

    moveToNextStep();
  };

  const buttons = (
    <div className={clsx('flex w-full', isBackButtonVisible ? 'justify-between' : 'justify-end')}>
      {isBackButtonVisible && (
        <Button
          type="button"
          variant="outlined"
          color="basic"
          size="lg"
          className="w-[108px]"
          startIcon={<ChevronLeftIcon />}
          iconClassName="fill-textColor-tertiary"
          onClick={() => moveToPreviousStep()}
        >
          Back
        </Button>
      )}

      <div className="flex justify-between gap-2">
        <Button
          type="button"
          variant="outlined"
          color="basic"
          size="lg"
          className="w-[108px]"
          onClick={() => toggleModal(isOpen)}
        >
          Cancel
        </Button>

        <Button
          type="button"
          color="primary"
          size="lg"
          className="w-[108px]"
          endIcon={isScheduleStepOpen ? <CheckmarkIcon /> : <ChevronRightIcon />}
          disabled={getIsPrimaryButtonDisabled()}
          onClick={() => handlePrimaryButtonClick()}
        >
          {isScheduleStepOpen ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  );

  const renderModalContent = () => {
    if (isDetailsStepOpen) {
      return detailsStepContent;
    }

    if (isOwnerStepOpen) {
      return ownerStepContent;
    }

    return scheduleStepContent;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="h-full max-h-[880px] overflow-y-auto"
    >
      <form
        className="flex flex-col justify-between gap-y-6 min-h-[845px] min-w-[998px]"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-y-6">
          <Typography
            variant="h2"
            fontWeight="bold"
          >
            {type === 'create' ? 'Create new ' : 'Edit '}
            Ticket
          </Typography>

          {currentStep}

          <div className="flex flex-col gap-y-4">{renderModalContent()}</div>
        </div>

        {buttons}
      </form>
    </Modal>
  );
};

export { CreateOrUpdateTicketModal };
export type { CreateOrUpdateTicketModalProps };
