import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { dayjs, not } from 'src/shared/utils';
import { useGetActivityByIdQuery } from 'src/store/api/activities';
import { ActivityHealth, ActivityTrack, ActivityUpdated } from 'src/shared/types';
import { Spinner } from 'src/shared/ui/spinner';

import { TaskManagementInfo } from '../taskManagementInfo';
import { TaskTimeInfo } from '../taskTimeInfo';
import { ActivityBadge } from '../activityBadge';

interface UpdatesListModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedJobId: string;
  timeZone: string;
}

const UpdatesListModal: FC<UpdatesListModalProps> = ({
  isOpen,
  setIsOpen,
  selectedJobId,
  timeZone,
}) => {
  const { data, isLoading } = useGetActivityByIdQuery(
    {
      id: selectedJobId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const updates = useMemo(() => {
    return data ? data.activityStates.slice(0, data.activityStates.length - 1) : [];
  }, [data]);

  const emptyWorkTypeSelect = (status: ActivityHealth | ActivityTrack | ActivityUpdated) => {};

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[646px]"
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-5"
      >
        Activity Updates
      </Typography>

      {isLoading && <Spinner fallbackText="Loading updates, please wait..." />}

      <div className="flex flex-col gap-3 max-h-[80vh] overflow-y-auto">
        {updates.map((update, index, array) => (
          <div
            key={update.id}
            className="border rounded-lg p-3 flex flex-col gap-2 w-full"
          >
            <div className="flex gap-3 items-center">
              <div className="size-4 rounded-full bg-brandingColor-primary-gradient flex items-center justify-center">
                <Typography
                  variant="c2"
                  className="text-[10px] leading-3 font-semibold text-center text-white"
                >
                  {array.length - index}
                </Typography>
              </div>

              <Typography
                variant="p2"
                fontWeight="bold"
                className="shrink-0"
              >
                {`Updated ${dayjs.utc(update.createdAt).tz(timeZone).format('M/D/YYYY h:mm A')}`}
              </Typography>
            </div>

            <Typography
              variant="p2"
              className="shrink-0"
            >
              <strong>Job Number: </strong>

              {`${update.jobNumber}`}
            </Typography>

            <Typography
              variant="p2"
              className="shrink-0"
            >
              <strong>Reported By: </strong>

              {`${update.reportedBy}`}
            </Typography>

            <div className="flex gap-2">
              {update.track !== 'Completed' && (
                <ActivityBadge
                  status={update.health}
                  onValueSelect={emptyWorkTypeSelect}
                  isBadge
                />
              )}

              <ActivityBadge
                status={update.track}
                onValueSelect={emptyWorkTypeSelect}
                isBadge
              />
            </div>

            <TaskManagementInfo
              activityName={update.name}
              provider={update.provider.ProviderName}
              crew={update.providerTeam.name}
              canShrink
            />

            <TaskTimeInfo
              endWork={dayjs.utc(update.endDate).tz(timeZone).toDate()}
              startWork={dayjs.utc(update.startDate).tz(timeZone).toDate()}
              isTimeColored
              timeZone={timeZone}
              hasOriginal={false}
            />

            <div className="flex h-max">
              <div className="w-2 rounded-l-lg shrink-0 bg-semanticColor-warning" />

              <div className="py-3 pr-1.5 pl-3 flex flex-1 flex-col gap-2 rounded-r-lg bg-bgColor-ticketCard border-y border-y-textColor-light border-r border-r-textColor-light">
                <div className="flex flex-1 flex-col gap-1">
                  <Typography
                    variant="c3"
                    className="text-xs leading-[18px]"
                  >
                    Job Notes:
                  </Typography>

                  <Typography
                    variant="c2"
                    className="text-xs leading-[18px] text-textColor-secondary"
                  >
                    {update.notes}
                  </Typography>
                </div>

                <div className="flex flex-1 flex-col gap-1">
                  <Typography
                    variant="c3"
                    className="text-xs leading-[18px]"
                  >
                    Job Delays:
                  </Typography>

                  <Typography
                    variant="c2"
                    className="text-xs leading-[18px] text-textColor-secondary"
                  >
                    {update.delayCategory?.DelayCategory}
                  </Typography>
                </div>

                <div className="flex flex-1 flex-col gap-1">
                  <Typography
                    variant="c3"
                    className="text-xs leading-[18px]"
                  >
                    Percent-Complete Estimate:
                  </Typography>

                  <Typography
                    variant="c2"
                    className="text-xs leading-[18px] text-textColor-secondary"
                  >
                    {`${update.progress || 0} %`}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export { UpdatesListModal };
