import clsx from 'clsx';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import {
  ActivityHealth,
  ActivityJob,
  ActivityTrack,
  ActivityUpdated,
  WorkTrack,
} from 'src/shared/types';
import { Icons } from 'src/assets/icons';
import { Typography } from 'src/shared/ui/typography';
import { RootState } from 'src/store';

import {
  CARD_SPREAD_BUTTON_RADIUS,
  getMinWidth,
  MAX_CARD_Z_INDEX,
  ONE_HOUR_DAY_WIDTH_BY_MINUTES,
  ONE_HOUR_WIDTH_BY_MINUTES,
} from '../../helpers';
import { ActivityProgress } from '../activityProgress';
import { ActivityBadge } from '../activityBadge';
import { TaskManagementInfo } from '../taskManagementInfo';
import { TaskTimeInfo } from '../taskTimeInfo';
import { ActivityJobUpdatesTooltip } from '../activityJobUpdatesTooltip';

interface CardContainerProps {
  hasMoreSpace: boolean;
  setMinCardWidthByContent: (width: number) => void;
  card: ActivityJob;
  cardMarginLeft: string;
  zIndex: number;
  cardWidth: number;
  minCardWidth: number;
  timeZone: string;
  isWideCard: boolean;
  handleSpreadCard: () => void;
  setIsAddUpdateModalOpen: () => void;
  setIsUpdatesListOpen: () => void;
  isAbsolute?: boolean;
}

const CardContainer: FC<CardContainerProps> = ({
  hasMoreSpace,
  setMinCardWidthByContent,
  card,
  cardMarginLeft,
  zIndex,
  cardWidth,
  minCardWidth,
  timeZone,
  isWideCard,
  handleSpreadCard,
  setIsAddUpdateModalOpen,
  setIsUpdatesListOpen,
  isAbsolute,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const isDayPeriod = useMemo(() => filterPeriod === 'day', [filterPeriod]);

  const updatesOfState = card.updates && card.updates.slice(1);

  useEffect(() => {
    if (cardRef.current) {
      const minWidth = getMinWidth(cardRef.current);
      setMinCardWidthByContent(minWidth);
    }
  }, [card.updates]);

  const [showUpdatesIndex, setShowUpdatesIndex] = useState<number | null>(null);

  const handleWorkTypeSelect = (
    status: ActivityHealth | ActivityTrack | ActivityUpdated,
    index?: number,
  ) => {
    if (status === WorkTrack.Updated && !index) {
      setIsUpdatesListOpen();
    }
    if (status === WorkTrack.Updated && index) {
      setShowUpdatesIndex(index);
    }
  };

  const hideUpdates = () => {
    setShowUpdatesIndex(null);
  };

  return (
    <div
      className={clsx(
        'flex h-[167px] shrink-0 transition-all duration-300 w-max',
        hasMoreSpace && 'relative pr-4',
      )}
      style={{
        marginLeft: isAbsolute ? '0px' : cardMarginLeft,
        zIndex: MAX_CARD_Z_INDEX - zIndex + (isWideCard ? 1 : 0),
      }}
    >
      <div
        className={clsx('flex h-auto shrink-0 overflow-x-hidden transition-all duration-300')}
        style={{
          width: `${isAbsolute ? minCardWidth : cardWidth}px`,
        }}
        data-tooltip-id={`updates-${card.id}`}
        ref={isWideCard ? undefined : cardRef}
      >
        <div
          className={clsx(
            'w-2.5 rounded-l-lg shrink-0',
            updatesOfState?.length ? 'bg-semanticColor-warning' : 'bg-[#14B78B]',
          )}
        />

        <div className="p-2 flex flex-1 flex-col gap-3 bg-white rounded-r-lg ">
          <div className="flex items-center justify-between gap-2 shrink-0">
            <Typography
              variant="p2"
              fontWeight="bold"
              className="shrink-0"
            >
              {card.jobNumber}
            </Typography>

            <div className="flex gap-2 shrink-0">
              <ActivityProgress progress={card.workProgress} />

              <ActivityBadge
                onValueSelect={handleWorkTypeSelect}
                status={card.workTrack}
              />

              {!!updatesOfState?.length && (
                <ActivityBadge
                  onValueSelect={handleWorkTypeSelect}
                  status={WorkTrack.Updated}
                  updatesCount={updatesOfState.length}
                />
              )}
            </div>
          </div>

          <TaskManagementInfo
            activityName={card.activityName}
            provider={card.providerName}
            crew={card.providerTeam.name}
          />

          <TaskTimeInfo
            endWork={card.actualEnd || card.endWork}
            startWork={card.actualStart || card.startWork}
            timeZone={timeZone}
          />

          {updatesOfState && (
            <div className="flex items-center shrink-0">
              {updatesOfState.map((update, index) => {
                const isUpdateBetween = dayjs(update.createdAt).isBetween(
                  dayjs(card.actualStart || card.startWork),
                  dayjs(card.actualEnd || card.endWork),
                );

                const distanceValue = isDayPeriod
                  ? ONE_HOUR_DAY_WIDTH_BY_MINUTES
                  : ONE_HOUR_WIDTH_BY_MINUTES;

                const leftMinutesDistance =
                  index > 0
                    ? dayjs(update.createdAt).diff(
                        dayjs(updatesOfState[index - 1].createdAt),
                        'minute',
                      ) * distanceValue
                    : dayjs(update.createdAt).diff(
                        dayjs(card.actualStart || card.startWork),
                        'minute',
                      ) * distanceValue;

                return (
                  <Fragment key={update.id}>
                    {isUpdateBetween && (
                      <div
                        className={clsx(
                          'h-px -mx-0.5',
                          index === 0 ? 'bg-transparent' : 'bg-semanticColor-warning',
                        )}
                        style={{
                          width: `${leftMinutesDistance}px`,
                        }}
                      />
                    )}

                    <button
                      type="button"
                      onClick={() => handleWorkTypeSelect(WorkTrack.Updated, index + 1)}
                    >
                      <Icons.Outlined.Notifications.AlertCircleIcon
                        className={clsx(
                          'fill-semanticColor-warning w-5 h-5',
                          !isUpdateBetween && 'hidden',
                        )}
                      />
                    </button>
                  </Fragment>
                );
              })}
            </div>
          )}

          <div className="flex gap-2 items-center justify-end">
            <button
              type="button"
              onClick={setIsAddUpdateModalOpen}
            >
              <Typography
                variant="p2"
                fontWeight="bold"
                className="text-brandingColor-primary-gradient"
              >
                Add Update
              </Typography>
            </button>
          </div>
        </div>

        {updatesOfState && (
          <ActivityJobUpdatesTooltip
            isOpen={typeof showUpdatesIndex === 'number'}
            indexOfUpdate={typeof showUpdatesIndex === 'number' ? showUpdatesIndex : 0}
            card={card}
            hideUpdates={hideUpdates}
            timeZone={timeZone}
          />
        )}

        {hasMoreSpace && (
          <button
            type="button"
            className={clsx(
              'flex items-center justify-center p-1 bg-brandingColor-primary-gradient rounded-full border border-textColor-light absolute top-1/2 right-0 transform -translate-y-1/2 transition-all duration-300',
              isWideCard && 'rotate-180',
            )}
            style={{
              width: `${CARD_SPREAD_BUTTON_RADIUS * 2}px`,
              height: `${CARD_SPREAD_BUTTON_RADIUS * 2}px`,
            }}
            onClick={handleSpreadCard}
          >
            <Icons.Outlined.Chevrons.ArrowLeftIcon className="fill-white" />
          </button>
        )}
      </div>
    </div>
  );
};

export { CardContainer };
