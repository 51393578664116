import clsx from 'clsx';
import { ComponentProps, FC, ReactNode } from 'react';

import { Typography } from 'src/shared/ui/typography';

type DropDownItemMenuProps = ComponentProps<'div'> & {
  titleClassName?: string;
  endIcon?: ReactNode;
};

const DropDownItemMenu: FC<DropDownItemMenuProps> = ({
  children,
  titleClassName,
  endIcon,
  ...props
}) => {
  return (
    <div
      className="flex flex-1 h-[48px] items-center"
      {...props}
    >
      <div className="flex items-center justify-between flex-1 px-[16px] gap-x-2 w-full cursor-auto">
        <Typography
          className={clsx(titleClassName)}
          variant="p1"
        >
          {children}
        </Typography>

        {endIcon}
      </div>
    </div>
  );
};

export { DropDownItemMenu };
export type { DropDownItemMenuProps };
