import { FC, PropsWithChildren, HTMLAttributes } from 'react';
import clsx from 'clsx';

type IHeaderModal = PropsWithChildren<HTMLAttributes<HTMLElement>>;

const HeaderModal: FC<IHeaderModal> = ({ children, className, ...rest }) => {
  return (
    <header
      className={clsx('w-full sticky top-0 z-10 bg-bgColor-card shadow-md py-4 px-4', className)}
      {...rest}
    >
      {children}
    </header>
  );
};

export { HeaderModal };
