import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BoardLayout } from 'src/shared/types';

type InitialState = {
  currentLayout: BoardLayout;
  dates: string[];
  selectedDate: string;
};

const initialState: InitialState = {
  currentLayout: 'week',
  dates: [],
  selectedDate: '',
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    changeLayout: (state, action: PayloadAction<{ layout: BoardLayout }>) => {
      state.currentLayout = action.payload.layout;
    },
    changeDates: (state, action: PayloadAction<{ dates: string[] }>) => {
      state.dates = action.payload.dates;
    },
    changeSelectedDate: (state, action: PayloadAction<{ date: string }>) => {
      state.selectedDate = action.payload.date;
    },
  },
});

const calendarReducer = calendarSlice.reducer;
const calendarActions = calendarSlice.actions;

export { calendarReducer, calendarActions };
