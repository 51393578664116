import { FC, PropsWithChildren, HTMLAttributes } from 'react';
import clsx from 'clsx';

type IContainerModal = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

const ContainerModal: FC<IContainerModal> = ({ children, className, ...rest }) => {
  return (
    <div
      className={clsx('relative flex flex-col justify-between gap-y-6', className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export { ContainerModal };
