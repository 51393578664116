import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { ReactComponent as CalendarIcon } from 'src/assets/icons/outlined/misc/calendar.svg';
import { ReactComponent as MicrosoftIcon } from 'src/assets/icons/outlined/social/microsoft.svg';
import { API_URL } from 'src/config';
import { Icon } from 'src/shared/ui/icon';
import { Button } from 'src/shared/ui/button';
import { User, authActions, selectCurrentUser } from 'src/store/slices';
import { getTenant } from 'src/shared/utils/tenant';
import { useGetPortalProviderSettingsQuery } from 'src/store/api';
import { Spinner } from 'src/shared/ui/spinner';
import { normalizeUrl } from 'src/shared/utils';

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = authActions;
  const [searchParams] = useSearchParams();
  const user = useSelector(selectCurrentUser);
  const token = searchParams.get('token');

  const { data: configuration, isLoading: isConfigLoading } = useGetPortalProviderSettingsQuery(
    {},
    {
      skip: !user?.accessToken,
    },
  );

  const parsedUser = useMemo(
    () =>
      token
        ? ({
            ...jwtDecode(token),
            accessToken: token,
          } as User)
        : null,
    [token],
  );

  useEffect(() => {
    if (parsedUser) {
      dispatch(login(parsedUser));
    }
  }, [dispatch, login, parsedUser, token]);

  useEffect(() => {
    if (user?.accessToken && !isConfigLoading) {
      const redirectUrl = normalizeUrl(configuration?.PortalUrl);

      navigate(redirectUrl);
    }
  }, [navigate, user?.accessToken, isConfigLoading]);

  const handleMicrosoftSignIn = () => {
    const { tenant, env } = getTenant();

    const envPart = (env ? `-${env}` : '') as `-${string}` | '';

    window.location.replace(`${API_URL}/api/auth/microsoft/${tenant}${envPart}`);
  };

  return (
    <div className="flex items-center justify-center w-full h-full bg-[#EFF0F4]">
      {user?.accessToken && isConfigLoading ? (
        <Spinner
          withBackdrop
          fallbackText="Authenticating, please wait..."
        />
      ) : (
        <div className="flex items-center justify-center flex-col gap-[52px] p-[52px] rounded-2xl bg-textColor-white w-[550px]">
          <div className="flex items-center justify-center gap-[13px]">
            <Icon
              icon={<CalendarIcon />}
              className="fill-brandingColor-primary-gradient w-[40px] h-[40px]"
            />

            <span className="font-bold text-[40px] leading-10 text-black">
              <span className="text-brandingColor-primary-gradient">V</span>
              alorian
            </span>
          </div>

          <Button
            size="lg"
            color="primary"
            className="flex w-full"
            startIcon={<MicrosoftIcon />}
            iconClassName="fill-brandingColor-primary-gradient mr-[14px]"
            onClick={handleMicrosoftSignIn}
          >
            Sign in with Microsoft
          </Button>

          <div className="font-normal text-base text-textColor-secondary">
            {dayjs().year()}, Valorian, All rights Reserved
          </div>
        </div>
      )}
    </div>
  );
};

export { Auth };
