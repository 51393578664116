import { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { Button } from 'src/shared/ui/button';
import { Modal } from 'src/shared/ui/modal';
import { Typography } from 'src/shared/ui/typography';
import { dayjs, not, showToastErrorMessage } from 'src/shared/utils';
import { Icons } from 'src/assets/icons';
import { SelectInput, SelectInputItem } from 'src/shared/ui/selectInput';
import { TextField } from 'src/shared/ui/textField';
import {
  useGetActivityProvidersQuery,
  useGetEquipmentTypesQuery,
  useGetProviderTeamsByIdQuery,
  usePostNewActivityByProviderTeamMutation,
} from 'src/store/api/activities';
import { Datepicker } from 'src/shared/ui/datepicker';

import {
  addJobSchema,
  DEFAULT_SELECT_VALUE,
  getDayHours,
  getHoursWithoutMinutes,
} from '../../helpers';
import { TextAreaInput } from '../textAreaInput';

interface AddActivityJobModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  timeZone: string;
  ownerLocationID: string;
}

const AddActivityJobModal: FC<AddActivityJobModalProps> = ({
  isOpen,
  setIsOpen,
  timeZone,
  ownerLocationID,
}) => {
  const toggleModal = (isOpen: boolean) => {
    setIsOpen(not(isOpen));
  };

  const { data: providerValues } = useGetActivityProvidersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: equipmentTypeValues } = useGetEquipmentTypesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const providerVariants = useMemo(() => {
    return (
      providerValues?.map((provider) => ({
        id: provider.ProviderID,
        name: provider.ProviderName,
      })) || [DEFAULT_SELECT_VALUE]
    );
  }, [providerValues]);

  const providerEquipmentTypeVariants = useMemo(() => {
    return (
      equipmentTypeValues?.map((resp) => ({
        name: resp.EquipmentType,
        id: resp.EquipmentTypeID,
      })) || [DEFAULT_SELECT_VALUE]
    );
  }, [equipmentTypeValues]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const dayHours = getDayHours();
  const hoursWithoutMinutes = getHoursWithoutMinutes();

  const [createNewActivity] = usePostNewActivityByProviderTeamMutation();

  const onSubmit = async (data: z.infer<typeof addJobSchema>) => {
    try {
      const resp = await createNewActivity({
        teamId: data.providerTeam.id,
        ownerLocationID,
        reportedBy: data.reportedBy,
        jobNumber: data.jobNumber,
        equipment: data.equipment,
        equipmentTypeId: data.type.id,
        progress: 0,
        providerId: data.provider.id,
        health: 'OnTrack',
        track: 'SetStatus',
        providerTeamId: data.providerTeam.id,
        startDate: dayjs.utc(data.startTime).toISOString(),
        endDate: dayjs.utc(data.endTime).toISOString(),
        notes: data.notes || undefined,
        name: data.activityName,
        jobName: data.jobName,
      }).unwrap();

      if (typeof resp === 'object') {
        closeModal();
      }

      if (typeof resp === 'string') {
        showToastErrorMessage(resp);
      }
    } catch (error) {
      showToastErrorMessage('There was an error trying to create a job');
    }
  };

  const initDateRage = useMemo(() => {
    return {
      startDate: dayjs.utc().tz(timeZone).toISOString(),
      endDate: dayjs.utc().tz(timeZone).toISOString(),
    };
  }, [timeZone]);

  const initialValues = {
    reportedBy: '',
    activityName: '',
    jobName: '',
    jobNumber: '',
    equipment: '',
    type: DEFAULT_SELECT_VALUE,
    startTime: '',
    duration: '',
    endTime: '',
    providerTeam: DEFAULT_SELECT_VALUE,
    provider: DEFAULT_SELECT_VALUE,
    notes: '',
    dateRange: initDateRage,
  };

  const { values, handleChange, handleSubmit, setFieldValue, errors } = useFormik({
    onSubmit,
    validationSchema: toFormikValidationSchema(addJobSchema),
    initialValues,
    enableReinitialize: true,
  });

  const { data: providerTeamValues } = useGetProviderTeamsByIdQuery(
    {
      id: values.provider.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !values.provider.id,
    },
  );

  const providerTeamVariants = useMemo(() => {
    return providerTeamValues || [DEFAULT_SELECT_VALUE];
  }, [providerTeamValues]);

  useEffect(() => {
    setFieldValue('dateRange', initDateRage);
  }, [initDateRage]);

  useEffect(() => {
    if (values.duration) {
      const startHour = dayjs.utc(values.startTime).tz(timeZone).get('hour');
      const newDateEnd = dayjs
        .utc(values.startTime)
        .tz(timeZone)
        .set('hour', Number(values.duration) + startHour)
        .set('minute', 0);
      setFieldValue('endTime', newDateEnd.toISOString());
    } else if (values.endTime) {
      const newDuration = dayjs(values.endTime).diff(dayjs(values.startTime), 'hour');
      setFieldValue('duration', `${newDuration}`);
    }
  }, [values.startTime]);

  useEffect(() => {
    if (values.startTime) {
      const startHour = dayjs.utc(values.startTime).tz(timeZone).get('hour');
      const newDateEnd = dayjs
        .utc(values.startTime)
        .tz(timeZone)
        .set('hour', Number(values.duration) + startHour)
        .set('minute', 0);
      setFieldValue('endTime', newDateEnd.toISOString());
    } else if (values.endTime) {
      const newStartDate = dayjs
        .utc(values.endTime)
        .tz(timeZone)
        .subtract(Number(values.duration), 'hour');
      setFieldValue('startTime', newStartDate.toISOString());
    }
  }, [values.duration]);

  useEffect(() => {
    if (values.startTime) {
      const newDuration = dayjs
        .utc(values.endTime)
        .tz(timeZone)
        .diff(dayjs.utc(values.startTime).tz(timeZone), 'hour');
      setFieldValue('duration', `${newDuration}`);
    } else if (values.duration) {
      const newStartDate = dayjs
        .utc(values.endTime)
        .tz(timeZone)
        .subtract(Number(values.duration), 'hour');
      setFieldValue('startTime', newStartDate.toISOString());
    }
  }, [values.endTime]);

  useEffect(() => {
    if (values.startTime) {
      const startHour = dayjs.utc(values.startTime).tz(timeZone).get('hour');
      const startMinutes = dayjs.utc(values.startTime).tz(timeZone).get('minute');

      setFieldValue(
        'startTime',
        dayjs
          .utc(values.dateRange.startDate)
          .tz(timeZone)
          .set('hour', startHour)
          .set('minute', startMinutes)
          .toISOString(),
      );
    }
    if (values.endTime) {
      const endHour = dayjs.utc(values.endTime).tz(timeZone).get('hour');
      const endMinutes = dayjs.utc(values.endTime).tz(timeZone).get('minute');

      setFieldValue(
        'endTime',
        dayjs
          .utc(values.dateRange.endDate)
          .tz(timeZone)
          .set('hour', endHour)
          .set('minute', endMinutes)
          .toISOString(),
      );
    }
  }, [values.dateRange]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      customClassName="w-[787px]"
    >
      <Typography
        variant="h2"
        fontWeight="bold"
        className="mb-10"
      >
        Add Discovery
      </Typography>

      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col justify-between gap-y-6 w-full h-[616px] overflow-y-auto p-1">
          <TextField
            name="activityName"
            label="Activity Name"
            placeholder="Activity Name..."
            required
            isRequired
            value={values.activityName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'activityName',
                  value: value.target.value,
                },
              })
            }
            error={errors.activityName}
          />

          <TextField
            name="jobNumber"
            label="Job Id"
            placeholder="Job Id..."
            required
            isRequired
            value={values.jobNumber}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'jobNumber',
                  value: value.target.value,
                },
              })
            }
            error={errors.jobNumber}
          />

          <TextField
            name="jobName"
            label="Job Name"
            placeholder="Job Name..."
            required
            isRequired
            value={values.jobName}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'jobName',
                  value: value.target.value,
                },
              })
            }
            error={errors.jobName}
          />

          <TextField
            name="reportedBy"
            label="Reported By"
            placeholder="Reported By..."
            required
            isRequired
            value={values.reportedBy}
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'reportedBy',
                  value: value.target.value,
                },
              })
            }
            error={errors.reportedBy}
          />

          <div className="flex gap-x-8 w-full">
            <TextField
              name="equipment"
              label="Equipment"
              value={values.equipment}
              placeholder="Equipment..."
              onChange={(value) =>
                handleChange({
                  target: {
                    name: 'equipment',
                    value: value.target.value,
                  },
                })
              }
              error={errors.equipment}
            />

            <SelectInput
              isRequired
              required
              name="type"
              label="Type"
              value={values.type.name}
              placeholder="Type..."
              onClear={() => setFieldValue('type', DEFAULT_SELECT_VALUE)}
              items={
                providerEquipmentTypeVariants.map(({ id, name }) => ({
                  label: <SelectInputItem selected={values.type.id === id}>{name}</SelectInputItem>,
                  value: id,
                  onClick: () => {
                    handleChange({
                      target: {
                        name: 'type',
                        value: {
                          id,
                          name,
                        },
                      },
                    });
                  },
                })) ?? []
              }
            />
          </div>

          <Typography
            variant="p1"
            fontWeight="bold"
          >
            Work Time
          </Typography>

          <div className="flex flex-col gap-4 rounded-lg border border-[#E4E9F2] px-3 py-4">
            <div className="flex justify-between items-center gap-1">
              <Typography
                variant="p1"
                fontWeight="bold"
              >
                Due Date
              </Typography>

              <div className="p-2.5 bg-[#EFF1F5] rounded-lg w-max">
                <Icons.Outlined.Misc.CalendarIcon className="fill-[#1F1F1F] w-5 h-5" />
              </div>
            </div>

            <Datepicker
              value={values.dateRange}
              timezone={timeZone}
              isTimezoneFormatted
              onChange={(value) => {
                handleChange({
                  target: {
                    name: 'dateRange',
                    value: {
                      startDate: dayjs.utc(value?.startDate).tz(timeZone).toISOString(),
                      endDate: dayjs.utc(value?.endDate).tz(timeZone).toISOString(),
                    },
                  },
                });
              }}
            />
          </div>

          <div className="flex gap-4">
            <SelectInput
              isRequired
              required
              name="startTime"
              label="Start Time"
              value={
                values.startTime ? dayjs.utc(values.startTime).tz(timeZone).format('HH:mm') : ''
              }
              placeholder="11:00"
              items={
                dayHours.map((option) => ({
                  label: (
                    <SelectInputItem
                      selected={dayjs.utc(values.startTime).tz(timeZone).format('HH:mm') === option}
                    >
                      {option}
                    </SelectInputItem>
                  ),
                  value: option,
                  onClick: () => {
                    const [hours, minutes] = option.split(':');
                    handleChange({
                      target: {
                        name: 'startTime',
                        value: dayjs
                          .utc(values.dateRange.startDate)
                          .tz(timeZone)
                          .set('hour', Number(hours === '24' ? '0' : hours))
                          .set('minute', Number(minutes))
                          .toISOString(),
                      },
                    });
                  },
                })) ?? []
              }
            />

            <SelectInput
              isRequired
              required
              name="duration"
              label="Duration"
              value={values.duration}
              placeholder="Duration..."
              items={
                hoursWithoutMinutes.map((option) => ({
                  label: (
                    <SelectInputItem selected={values.duration === option}>
                      {option}
                    </SelectInputItem>
                  ),
                  value: option,
                  onClick: () => {
                    handleChange({
                      target: {
                        name: 'duration',
                        value: option,
                      },
                    });
                  },
                })) ?? []
              }
            />

            <SelectInput
              isRequired
              required
              name="endTime"
              label="End Time"
              value={values.endTime ? dayjs.utc(values.endTime).tz(timeZone).format('HH:mm') : ''}
              placeholder="12:00"
              items={
                dayHours.map((option) => ({
                  label: (
                    <SelectInputItem
                      selected={dayjs.utc(values.endTime).tz(timeZone).format('HH:mm') === option}
                    >
                      {option}
                    </SelectInputItem>
                  ),
                  value: option,
                  onClick: () => {
                    const [hours, minutes] = option.split(':');
                    handleChange({
                      target: {
                        name: 'endTime',
                        value: dayjs
                          .utc(values.dateRange.endDate)
                          .tz(timeZone)
                          .set('hour', Number(hours === '24' ? '0' : hours))
                          .set('minute', Number(minutes))
                          .toISOString(),
                      },
                    });
                  },
                })) ?? []
              }
            />
          </div>

          <SelectInput
            isRequired
            required
            name="provider"
            label="Assign Provider"
            value={values.provider.name}
            placeholder="Assign provider ..."
            disabled={!providerVariants}
            onClear={() => setFieldValue('provider', DEFAULT_SELECT_VALUE)}
            items={
              providerVariants.map(({ id, name }) => ({
                label: (
                  <SelectInputItem selected={values.provider.name === name}>{name}</SelectInputItem>
                ),
                value: id,
                onClick: () => {
                  handleChange({
                    target: {
                      name: 'provider',
                      value: {
                        id,
                        name,
                      },
                    },
                  });
                },
              })) ?? []
            }
          />

          <SelectInput
            isRequired
            required
            name="providerTeam"
            label="Assign Provider Team"
            value={values.providerTeam.name}
            placeholder="Assign provider team ..."
            disabled={!providerTeamValues || !values.provider.id}
            onClear={() => setFieldValue('providerTeam', DEFAULT_SELECT_VALUE)}
            items={
              providerTeamVariants.map(({ id, name }) => ({
                label: (
                  <SelectInputItem selected={values.providerTeam.name === name}>
                    {name}
                  </SelectInputItem>
                ),
                value: id,
                onClick: () => {
                  handleChange({
                    target: {
                      name: 'providerTeam',
                      value: {
                        id,
                        name,
                      },
                    },
                  });
                },
              })) ?? []
            }
          />

          <TextAreaInput
            onChange={(value) =>
              handleChange({
                target: {
                  name: 'notes',
                  value,
                },
              })
            }
            value={values.notes}
            label="Notes"
            placeholder="Add notes"
          />
        </div>

        <div className="flex justify-end gap-2 -mx-4 pt-4 pr-4 border-t border-t-textColor-light">
          <Button
            type="button"
            variant="outlined"
            color="basic"
            size="lg"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            size="lg"
            endIcon={<Icons.Outlined.Edit.CheckmarkIcon className="fill-white" />}
            autoFocus
          >
            Add
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export { AddActivityJobModal };
