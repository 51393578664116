import { forwardRef } from 'react';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import { Icon } from 'src/shared/ui/icon';

import { type ButtonProps } from '../button';

type OutlinedButtonProps = ButtonProps & {
  buttonClassName?: string;
};

const OutlinedButton = forwardRef<HTMLButtonElement, OutlinedButtonProps>(
  (
    {
      children,
      className,
      buttonClassName,
      color = 'basic',
      darkBg,
      iconClassName,
      startIcon,
      endIcon,
      size,
      ...props
    },
    ref,
  ) => {
    const colorClasses = match(color)
      // | Outlined button styles.
      .when(
        () => darkBg,
        () =>
          `bg-transparent border border-textColor-white text-textColor-white
           hover:bg-[#00000026]
           active:bg-[#00000033]
           transition-colors
           `,
      )
      .with(
        'basic',
        () =>
          'bg-transparent border border-textColor-tertiary text-textColor-tertiary hover:border-[#596E96] hover:text-[#596E96] active:border-[#506286] active:text-[#506286]',
      )
      .with(
        'primary',
        () => `bg-transparent border border-brandingColor-primary text-brandingColor-primary`,
      )
      .with(
        'success',
        () => 'bg-transparent border border-semanticColor-success text-semanticColor-success',
      )
      .with('info', () => 'bg-transparent border border-semanticColor-info text-semanticColor-info')
      .with(
        'warning',
        () => 'bg transparent border border-semanticColor-warning text-semanticColor-warning',
      )
      .with(
        'danger',
        () => 'bg-transparent border border-semanticColor-danger text-semanticColor-danger',
      )
      .otherwise(() => '');

    return (
      <button
        ref={ref}
        type="button"
        className={clsx(buttonClassName, colorClasses, className)}
        {...props}
      >
        {startIcon && (
          <Icon
            icon={startIcon}
            size={size}
            color={darkBg ? 'light' : 'dark'}
            className={iconClassName}
          />
        )}

        <span>{children}</span>

        {endIcon && (
          <Icon
            icon={endIcon}
            size={size}
            color={darkBg ? 'light' : 'dark'}
            className={iconClassName}
          />
        )}
      </button>
    );
  },
);

OutlinedButton.displayName = 'OutlinedButton';

export { OutlinedButton };
export type { OutlinedButtonProps };
