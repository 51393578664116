import { FC } from 'react';
import clsx from 'clsx';

import { Typography } from 'src/shared/ui/typography';
import { TextField } from 'src/shared/ui/textField';
import { Checkbox } from 'src/shared/ui/checkbox';
import { Datepicker } from 'src/shared/ui/datepicker';
import { DateValueType } from 'src/shared/ui/datepicker/types';
import { TypeNotation, RelationOptionsMap, MetadataMap, RelationOption } from 'src/shared/types';
import { Icons } from 'src/assets/icons';
import { DropDown } from 'src/shared/ui/dropDown';
import { DropDownItemMenu } from 'src/shared/ui/dropDown/ui/dropDownItemMenu';
import { isStringArray } from 'src/shared/utils';
import { MultiSelectInput } from 'src/shared/ui/MultiSelectInput';
import { SelectInputItem } from 'src/shared/ui/selectInput';

interface IRowFieldProps {
  schemaElement: TypeNotation;
  relationOptions: RelationOptionsMap;
  selectedRow: Record<string, unknown>;
  fieldsMetadata: MetadataMap;
}

const RowField: FC<IRowFieldProps> = ({
  schemaElement: { field, type },
  relationOptions,
  selectedRow,
  fieldsMetadata,
}) => {
  const label = fieldsMetadata?.headerNames?.[field]?.name ?? field;
  if (
    field in relationOptions &&
    fieldsMetadata?.multiSelectFields?.includes(field) &&
    Array.isArray(selectedRow[field])
  ) {
    const currentValues = selectedRow[field] as string[];
    const currentOptions = Array.isArray(currentValues)
      ? relationOptions[field].filter((el) => currentValues.includes(el.value))
      : [];

    const multiSelectValue: RelationOption[] = currentOptions.length > 0 ? currentOptions : [];

    return (
      <MultiSelectInput
        placeholder={`Select ${field}`}
        label={label}
        value={currentValues}
        items={
          relationOptions[field].map((option) => {
            return {
              label: (
                <SelectInputItem
                  selected={multiSelectValue.some((item) => {
                    return item.value === option.value;
                  })}
                >
                  {option.label}
                </SelectInputItem>
              ),
              identifier: option.value,
              value: option.label,
              onClick: () => {},
            };
          }) ?? []
        }
      />
    );
  }

  if (field in relationOptions) {
    const selectedValue = relationOptions[field].find((el) => el.value === selectedRow[field]);

    return (
      <TextField
        label={label}
        value={selectedValue?.label || ''}
        onChange={() => {}}
      />
    );
  }

  if (typeof relationOptions[field] === 'boolean') {
    return (
      <div className={clsx('flex flex-col gap-y-2 w-full')}>
        <Typography variant="label">{label}</Typography>

        <Checkbox
          endLabel={`Select ${field}`}
          name={field}
          checked={selectedRow[field] as boolean}
          onChange={() => {}}
          disabled
        />
      </div>
    );
  }

  if (type === 'date') {
    return (
      <div className={clsx('flex flex-col gap-y-2 w-full')}>
        <Typography variant="label">{label}</Typography>

        <Datepicker
          value={
            {
              startDate: selectedRow[field],
              endDate: selectedRow[field],
            } as DateValueType
          }
          onChange={() => {}}
          asSingle
          disabled
        />
      </div>
    );
  }

  if (type === 'array' && isStringArray(selectedRow[field])) {
    const items: string[] = selectedRow[field] as string[];

    return (
      <div className={clsx('flex flex-col gap-y-2 w-full')}>
        <TextField
          label={label}
          value=""
          onChange={() => {}}
          startIcon={
            <DropDown
              options={{ placement: 'bottom-start' }}
              config={{
                closeOnSelect: false,
                focusLastElement: false,
                itemsElementClassName: (isOpen) =>
                  clsx(
                    'shadow-[0px_2px_66px_-10px_#0000000F]',
                    'rounded-lg',
                    'bg-[#F3F6FA]',
                    'w-full',
                    'p-4',
                    !isOpen && 'p-0 border-0',
                  ),
              }}
              renderElement={(isOpen) => (
                <div className="absolute left-1 w-[40px] flex justify-center items-center">
                  <Icons.Outlined.Chevrons.ArrowUpIcon
                    className={clsx(
                      'fill-[#2E3A59] transition-all cursor-pointer',
                      isOpen ? 'rotate-0' : 'rotate-180',
                    )}
                  />
                </div>
              )}
              items={items.map((item) => ({
                value: item,
                label: (
                  <DropDownItemMenu titleClassName="text-textColor-tertiary">
                    {item}
                  </DropDownItemMenu>
                ),
              }))}
            />
          }
          endIcon={
            <div className="absolute right-1">
              <Typography
                variant="h3"
                className="flex items-center justify-center size-[40px] text-semanticColor-success"
              >
                {items.length}
              </Typography>
            </div>
          }
        />
      </div>
    );
  }

  if (
    typeof selectedRow[field] === 'string' ||
    typeof selectedRow[field] === 'number' ||
    !selectedRow[field]
  ) {
    return (
      <TextField
        type={type}
        name={field}
        label={label}
        value={(selectedRow[field] as string) || ''}
        onChange={() => {}}
      />
    );
  }

  return null;
};

export { RowField };
