import { ReactComponent as CheckmarkCircle } from './checkmark-circle.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as SaveIcon } from './save.svg';
import { ReactComponent as TrashIcon } from './trash.svg';
import { ReactComponent as CheckmarkIcon } from './checkmark.svg';

export const Edit = {
  CheckmarkCircle,
  CloseIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon,
  CheckmarkIcon,
};
