import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Filters } from 'src/shared/ui/filters';
import { TextField } from 'src/shared/ui/textField';
import { RootState } from 'src/store';
import { useGetActivitiesByProviderTeamsQuery } from 'src/store/api/activities';
import { IconButton } from 'src/shared/ui/iconButton';
import { Icons } from 'src/assets/icons';
import { ChoosingPeriodButtons } from 'src/shared/ui/choosingPeriodButtons';
import { DateRangeSelector } from 'src/shared/ui/dateRangeSelector';
import { UploadJobsButton } from 'src/shared/ui/uploadJobsButton';
import { Tag } from 'src/shared/ui/tag';
import { TimePeriod } from 'src/shared/types';
import { FilterSelect } from 'src/shared/ui/filterselect';
import { useGetActivityProcessesFiltersQuery } from 'src/store/api';

import { ActivityJobProcess, AddActivityJobButton, OneDaySector } from './ui';
import {
  DATES_VERTICAL_GAP,
  DAY_PADDING_BOTTOM,
  DAY_TITLE_HEIGHT,
  DEFAULT_LOCATION_TIME_ZONE,
  DEFAULT_OWNER_LOCATION_ID,
  HEADER_HEIGHT,
  HOURS_IN_DAY,
  ONE_HOUR_DAY_WIDTH,
  ONE_HOUR_WIDTH,
} from './helpers';

const Activities: FC = () => {
  const params = useParams();

  const { activityId } = params;

  const datesState = useSelector((state: RootState) => state.calendar.dates);
  const filterPeriod = useSelector((state: RootState) => state.calendar.currentLayout);
  const boardFilters = useSelector((state: RootState) => state.config.boardFilters);

  const isDayPeriod = useMemo(() => filterPeriod === TimePeriod.Day, [filterPeriod]);

  const dates = useMemo(
    () => datesState.map((date) => dayjs(date).format().toString()),
    [datesState],
  );

  const { startDate, endDate } = useMemo(() => {
    const localStartDate = dayjs(dates[0])
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .toDate();
    const localEndDate = dayjs(dates[dates.length - 1])
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .toDate();

    const startDate = dayjs.utc(localStartDate).toDate().toISOString();
    const endDate = dayjs.utc(localEndDate).toDate().toISOString();

    return {
      startDate,
      endDate,
    };
  }, [dates]);

  const { data: activitiesByProviderTeams } = useGetActivitiesByProviderTeamsQuery(
    {
      ...boardFilters,
      startDate,
      endDate,
    },
    {
      skip: !!activityId,
      refetchOnMountOrArgChange: true,
    },
  );

  const preparedDates = useMemo(() => {
    return isDayPeriod
      ? [dayjs(dates[0]).format('dddd, MMMM D')]
      : dates.map((date) => dayjs(date).format('dddd, MMMM D'));
  }, [dates, isDayPeriod]);

  const [search, setSearch] = useState('');
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  const toggleFilterMenu = () => setIsFilterMenuOpen((prev) => !prev);

  const { data: filters = [] } = useGetActivityProcessesFiltersQuery();

  const renderFilters = filters.map((filter) => (
    <FilterSelect
      key={`ActivityFilter-${filter.label}`}
      options={filter.options.map((option) => ({
        label: option,
        value: option,
      }))}
      label={filter.label}
      placeholder={`Filter by ${filter.label}`}
      id={filter.label}
      type="board"
    />
  ));

  const appliedFiltersAmount = Object.values(boardFilters).filter(
    (filter) => filter.length > 0,
  ).length;

  const { timeZone, ownerLocationID } = useMemo(() => {
    const firstActivity = activitiesByProviderTeams
      ? activitiesByProviderTeams[0]?.activity[0]
      : null;

    const timeZone =
      activitiesByProviderTeams && firstActivity
        ? firstActivity.OwnerLocation?.LocationTimezone
        : DEFAULT_LOCATION_TIME_ZONE;

    const ownerLocationID =
      activitiesByProviderTeams && firstActivity
        ? firstActivity.ownerLocationID
        : DEFAULT_OWNER_LOCATION_ID;

    return {
      timeZone,
      ownerLocationID,
    };
  }, [activitiesByProviderTeams]);

  const containerMinWidth = useMemo(() => {
    return (
      preparedDates.length * HOURS_IN_DAY * (isDayPeriod ? ONE_HOUR_DAY_WIDTH : ONE_HOUR_WIDTH)
    );
  }, [preparedDates, isDayPeriod]);

  const [containerWidth, setContainerWidth] = useState(containerMinWidth);

  const handleSetContainerWidth = (width?: number) => {
    if (width) {
      setContainerWidth(width);
    } else {
      setContainerWidth(containerMinWidth);
    }
  };

  useEffect(() => {
    setContainerWidth(containerMinWidth);
  }, [containerMinWidth]);

  const topSpace = HEADER_HEIGHT + DATES_VERTICAL_GAP + DAY_TITLE_HEIGHT + DAY_PADDING_BOTTOM;

  return (
    <div>
      <div className="p-6 flex gap-x-4 gap-y-2 items-center justify-center flex-wrap">
        <div className="flex gap-2">
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search for Jobs"
            className="w-[360px]"
            inputClassName="bg-white"
            startIcon={
              <Icons.Filled.InternetCode.Search className="absolute top-[13px] left-[13px] fill-[#7F8EB4]" />
            }
            endIcon={
              search ? (
                <IconButton
                  size="md"
                  className="absolute right-[4px] top-[4px]"
                  onClick={() => setSearch('')}
                >
                  <Icons.Outlined.Edit.CloseCircleIcon className="fill-[#2E3A59] cursor-pointer" />
                </IconButton>
              ) : undefined
            }
          />
        </div>

        <ChoosingPeriodButtons />

        <DateRangeSelector isActivityPage />

        <UploadJobsButton />

        <div className="relative">
          <IconButton
            size="none"
            iconSize="md"
            color="basic"
            className="h-[48px] w-[48px]"
            onClick={toggleFilterMenu}
          >
            <Icons.Outlined.Controls.FilterIcon />
          </IconButton>

          {!!appliedFiltersAmount && (
            <Tag
              type="white"
              className="absolute right-[-2px] top-0 !px-1"
            >
              {appliedFiltersAmount}
            </Tag>
          )}
        </div>

        {isFilterMenuOpen && (
          <Filters
            title="Filters"
            isOpen={isFilterMenuOpen}
            closeMenu={setIsFilterMenuOpen}
            type="board"
            className="right-[60px] top-[85px] min-w-[450px] max-w-[450px] z-[9999]"
          >
            {renderFilters}
          </Filters>
        )}
      </div>

      <div className="w-screen px-6 mt-3 flex justify-center">
        <div className="flex relative overflow-x-scroll">
          {preparedDates.map((date, index) => (
            <OneDaySector
              key={date}
              index={index}
              date={date}
            />
          ))}

          <div
            style={{
              scrollbarWidth: 'none',
              minWidth: `${containerWidth}px`,
              height: `calc(100vh - ${topSpace}px)`,
              paddingBottom: `${DAY_PADDING_BOTTOM}px`,
            }}
            className="absolute top-0 left-0 mt-[74px] overflow-scroll"
          >
            <div className="flex flex-col gap-4">
              {activityId && (
                <ActivityJobProcess
                  activityId={activityId}
                  processIndex={0}
                  setContainerWidth={handleSetContainerWidth}
                />
              )}

              {!activityId &&
                activitiesByProviderTeams?.map(({ id, activity }, index) => (
                  <ActivityJobProcess
                    key={id}
                    activityId={id}
                    processIndex={index}
                    activityCount={activity.length}
                    setContainerWidth={handleSetContainerWidth}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>

      <AddActivityJobButton
        timeZone={timeZone}
        ownerLocationID={ownerLocationID}
      />
    </div>
  );
};

export { Activities };
