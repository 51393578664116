import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';

type SvgRendererProps = {
  svgUrl: string;
  color?: string;
  className?: string;
  width?: string;
  height?: string;
};

const SvgRenderer: FC<SvgRendererProps> = ({
  svgUrl,
  color = '',
  className = '',
  width = '30',
  height = '30',
}) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(svgUrl);

        if (response.type === 'basic') {
          return;
        }

        const svgText = await response.text();

        // Parse SVG string to DOM element
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgText, 'image/svg+xml');

        // Traverse the DOM and set fill, width and height
        doc.querySelectorAll('*').forEach((element) => {
          element.setAttribute('fill', color || 'current');
          element.setAttribute('width', width);
          element.setAttribute('height', height);
        });

        // Convert DOM element back to SVG string
        const modifiedSvgText = new XMLSerializer().serializeToString(doc);

        setSvgContent(modifiedSvgText);
      } catch (error) {
        console.error('Error fetching SVG:', error);
      }
    };

    fetchSvg();
  }, [svgUrl]);

  return (
    <div
      className={clsx(className)}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export { SvgRenderer };
